import React from "react";
import styles from "./EventPage.module.scss";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import Navigation from "../Navigation";
import RewardsOverview from "./RewardsOverview";
import Legal from "./Legal";
import moment from "moment";
import { useRollbar } from "@rollbar/react";
import { ReduceAndRewardEvent } from "trust-insights-types";
import EventChart from "./EventChart";
import { useTrackPageVisit } from "../../hooks/useTrackPageVisit";
import { config } from "../../config";

interface EventPageProps {
  onLoadingChange: (loading: boolean) => void;
}

const EventPage: React.FC<EventPageProps> = (props) => {
  const { onLoadingChange } = props;
  const { accountId, eventId } = useParams();
  const rollbar = useRollbar();
  const [rewards, setRewards] = useState(null);
  const [chartDate, setChartDate] = useState<string | null>(null);
  const [chartData, setChartData] = useState(null);
  const [prevEventLink, setPrevEventLink] = useState<string | null>(null);
  const [nextEventLink, setNextEventLink] = useState<string | null>(null);

  useTrackPageVisit("event", accountId as string);

  const showLoading = useCallback(
    function showLoading() {
      onLoadingChange(true);
    },
    [onLoadingChange]
  );

  const hideLoading = useCallback(
    function hideLoading() {
      onLoadingChange(false);
    },
    [onLoadingChange]
  );

  const fetchDataError = useCallback(
    function fetchDataError(e: Error) {
      let message = "No data available for this event";
      rollbar.error(message, e);
    },
    [rollbar]
  );

  const fetchData = useCallback(
    async function fetchData() {
      try {
        showLoading();
        const response = await axios({
          method: "get",
          baseURL: config.reduceAndRewardUrl,
          url: "dr_events/event_progress",
          params: { account_id: accountId, event_id: eventId },
        });
        const response2 = await axios({
          method: "get",
          baseURL: config.reduceAndRewardUrl,
          url: "/tracker/dr_events",
          params: { account_id: accountId },
        });
        let challenge = response.data.challenge;
        if (challenge.status === "complete") {
          setChartDate(moment(String(challenge.date)).format("DD MMMM YYYY"));
        } else {
          setChartDate("Date missing");
        }
        setChartData(response.data);
        setRewards(response.data.usage.total_rewards);
        const eventIds = response2.data.dr_events.map(
          (event: ReduceAndRewardEvent) => event.dr_event.id
        );
        const indexOfCurrentEvent = eventIds.indexOf(Number(eventId));
        if (
          indexOfCurrentEvent === 0 &&
          indexOfCurrentEvent === eventIds.length - 1
        ) {
          setPrevEventLink(null);
          setNextEventLink(null);
        } else if (indexOfCurrentEvent === 0) {
          setPrevEventLink(null);
          setNextEventLink(
            `/ReduceAndReward/${accountId}/event/${
              eventIds[indexOfCurrentEvent + 1]
            }`
          );
        } else if (indexOfCurrentEvent === eventIds.length - 1) {
          setPrevEventLink(
            `/ReduceAndReward/${accountId}/event/${
              eventIds[indexOfCurrentEvent - 1]
            }`
          );
          setNextEventLink(null);
        } else {
          setPrevEventLink(
            `/ReduceAndReward/${accountId}/event/${
              eventIds[indexOfCurrentEvent - 1]
            }`
          );
          setNextEventLink(
            `/ReduceAndReward/${accountId}/event/${
              eventIds[indexOfCurrentEvent + 1]
            }`
          );
        }
      } catch (e) {
        fetchDataError(e as Error);
      }
      hideLoading();
    },
    [accountId, eventId, showLoading, hideLoading, fetchDataError]
  );

  useEffect(() => {
    document.title = "Reduce & Reward Portal";
    fetchData();
  }, [accountId, eventId, fetchData]);

  if (!accountId) {
    return (
      <div className={styles.error}>
        <h2>Missing account ID number</h2>
      </div>
    );
  }
  return (
    <div>
      <Navigation />
      <RewardsOverview
        account-id={accountId}
        rewards={rewards}
        showRank={true}
        totalRewards={null}
      />
      <EventChart
        chartData={chartData}
        prevEventLink={prevEventLink}
        nextEventLink={nextEventLink}
        chartDate={chartDate}
      />
      <Legal accountId={accountId} />
    </div>
  );
};
export default EventPage;
