import React from "react";
import OverviewProps from "../interfaces/OverviewProps";
import { wholeNumber, dollars, round } from "../services/Filters";
import styles from "./DayOverview.module.scss";

const DayOverview: React.FC<OverviewProps> = (props) => {
  return (
    <>
      <div className={styles.item}>
        {props.dailyInsightsData ? (
          <div className={styles.itemRight}>
            {props.dailyInsightsData.trend &&
            props.dailyInsightsData.trend.up ? (
              <img
                src="/static/icons/overview-arrow-up-engie.svg"
                alt="trend increase"
              />
            ) : props.dailyInsightsData.trend &&
              props.dailyInsightsData.trend.down ? (
              <img
                src="/static/icons/overview-arrow-down-engie.svg"
                alt="trend decrease"
              />
            ) : null}
          </div>
        ) : null}
        <div className={styles.itemLeft}>
          {props.dailyInsightsData &&
          props.dailyInsightsData.trend &&
          props.dailyInsightsData.trend.up ? (
            <div>
              <h5 className={styles.title}>
                {wholeNumber(props.dailyInsightsData.trend.value_to_times)}%
              </h5>
              <span>
                More than an average {props.intervalRange.starts_at.day}
              </span>
            </div>
          ) : props.dailyInsightsData &&
            props.dailyInsightsData.trend &&
            props.dailyInsightsData.trend.down ? (
            <div>
              <h5 className={styles.title}>
                {wholeNumber(
                  Math.abs(props.dailyInsightsData.trend.value_to_times),
                  "down"
                )}
                %
              </h5>
              <span>
                Less than an average {props.intervalRange.starts_at.day}
              </span>
            </div>
          ) : props.dailyInsightsData &&
            props.dailyInsightsData.trend &&
            props.dailyInsightsData.trend.stable ? (
            <div>
              <h5 className={styles.title}>
                {wholeNumber(
                  Math.abs(props.dailyInsightsData.trend.value_to_times),
                  "down"
                )}
                %
              </h5>
              <span>Average for a {props.intervalRange.starts_at.day}</span>
            </div>
          ) : props.dailyInsightsData ? (
            <div>
              <h5 className={styles.title}>--</h5>
              <span>No average use data for this day</span>
            </div>
          ) : null}
        </div>
      </div>
      <div className={styles.item}>
        {props.dailyInsightsData && props.dailyInsightsData.diversion.trend ? (
          <div className={styles.itemRight}>
            {props.dailyInsightsData.diversion.trend === "more" ? (
              <img
                src="/static/icons/overview-arrow-up-engie.svg"
                alt="diversion trend increase"
              />
            ) : props.dailyInsightsData.diversion.trend === "less" ? (
              <img
                src="/static/icons/overview-arrow-down-engie.svg"
                alt="diversion trend decrease"
              />
            ) : null}
          </div>
        ) : null}
        <div className={styles.itemLeft}>
          {props.dailyInsightsData && props.dailyInsightsData.diversion ? (
            <div>
              <h5 className={styles.title}>
                {wholeNumber(
                  Math.abs(props.dailyInsightsData.diversion.value_to_times),
                  "down"
                )}
                %
              </h5>
              {props.dailyInsightsData.diversion.trend === "more" ? (
                <span>
                  More than average in the{" "}
                  {props.dailyInsightsData.diversion.period}
                </span>
              ) : props.dailyInsightsData.diversion.trend === "less" ? (
                <span>
                  Less than average in the{" "}
                  {props.dailyInsightsData.diversion.period}
                </span>
              ) : props.dailyInsightsData ? (
                <span>No diversion data for this day</span>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
      {props.hasSolar && <div className={styles.itemBreakLine}></div>}
      {props.hasSolar ? (
        <div className={styles.item}>
          {props.dailyInsightsData ? (
            <div className={styles.itemRight}>
              <img
                src="/static/icons/overview-wallet-engie.svg"
                alt="feed-in credit earnt"
              />
            </div>
          ) : null}
          <div className={styles.itemLeft}>
            {props.dailyInsightsData && props.dailyInsightsData._total_solar ? (
              <div>
                <h5 className={styles.title}>
                  {dollars(
                    wholeNumber(props.dailyInsightsData._total_solar, "down"),
                    0
                  )}
                </h5>
                <span>
                  Feed-in credit earnt on {props.intervalRange.starts_at.day}
                </span>
              </div>
            ) : props.dailyInsightsData._total_solar === 0 ? (
              <div>
                <h5 className={styles.title}>$0</h5>
                <span>No solar export data for this day</span>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}

      {props.hasSolar ? (
        <div className={styles.item}>
          <div className={styles.itemRight}>
            <img
              src="/static/icons/overview-right-arrow-engie.svg"
              alt="electricity exported"
            />
          </div>
          <div className={styles.itemLeft}>
            {props.dailyInsightsData && props.dailyInsightsData._solar_kwh ? (
              <div>
                <h5 className={styles.title}>
                  {round(props.dailyInsightsData._solar_kwh, 0)} kWh
                </h5>
                <span>
                  Of electricity exported on {props.intervalRange.starts_at.day}
                </span>
              </div>
            ) : props.dailyInsightsData._total_solar === 0 ? (
              <div>
                <h5 className={styles.title}>0</h5>
                <span>No solar export data for this day</span>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default DayOverview;
