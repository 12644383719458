import React from 'react';
import { Link } from "react-router-dom";
import ChartNavigationProps from "../interfaces/ChartNavigationProps";
import { abbreviate } from "../services/Filters";
import styles from "./ChartDayNavigation.module.scss";

const ChartDayNavigation: React.FC<ChartNavigationProps> = (props) => {
  const { accountId, prevLink, nextLink, zoomLink, isDayView, intervalRange } =
    props;

  return (
    <div className={styles.chartNavigation}>
      <div className={styles.chartNavigationInner}>
        {prevLink && (
          <Link
            className={`${styles.button} ${styles.buttonPrevious}`}
            to={{
              pathname: `/new/usage/${accountId}/${prevLink.from}/${prevLink.day}`,
            }}
          >
            <div className={styles.buttonClicker}></div>
            <img
              className={styles.navigationIcon}
              src="/static/icons/navigation-arrow-left.svg"
              alt="Previous day"
            />
            <span className={styles.buttonHiddenLabel}>Previous day</span>
          </Link>
        )}
        <Link
          className={`${styles.button} ${styles.buttonZoom} ${styles.hidden} ${styles.display}`}
          to={{
            pathname: `/new/usage/${accountId}/${zoomLink.from}`,
          }}
        >
          <div className={styles.buttonClicker}></div>
          <svg
            className={styles.buttonIcon}
            viewBox="0 0 32 32"
            width="32"
            height="32"
          >
            <use xlinkHref="#navigation-zoom"></use>
          </svg>
          <span className={styles.buttonRoundedLabel}>Week</span>
        </Link>
        {isDayView && intervalRange && (
          <h3 className={styles.weekViewNavigation}>
            {intervalRange.starts_at && (
              <>
                <span className={styles.timedateDayShort}>{`${abbreviate(
                  intervalRange.starts_at.day
                )} `}</span>
                <span
                  className={styles.timedateDayLong}
                >{`${intervalRange.starts_at.day} `}</span>
                <span className={styles.timedateDate}>
                  {intervalRange.starts_at.date}{" "}
                </span>
                <span className={styles.timedateMonthShort}>
                  {abbreviate(intervalRange.starts_at.month)}
                </span>
                <span className={styles.timedateMonthLong}>
                  {intervalRange.starts_at.month}
                </span>
                <span className={styles.timedateYear}>
                  , {abbreviate(intervalRange.ends_at.yyyymmdd, 4)}
                </span>
              </>
            )}
          </h3>
        )}
        {nextLink && (
          <Link
            className={`${styles.button} ${styles.buttonNext}`}
            to={{
              pathname: `/new/usage/${accountId}/${nextLink.from}/${nextLink.day}`,
            }}
          >
            <div className="button--clicker"></div>
            <img
              className={styles.navigationIcon}
              src="/static/icons/navigation-arrow-right.svg"
              alt="Next day"
            />
            <span className={styles.buttonHiddenLabel}>Next day</span>
          </Link>
        )}
      </div>
    </div>
  );
};

export default ChartDayNavigation;
