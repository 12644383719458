import "./Icons.scss";

export default function Icons() {
  return (
    <div>
      <svg style={{ display: "none" }}>
        <defs>
          <symbol id="navigation-arrow_left">
            <polygon points="19 24 27 16 27 32" />
          </symbol>
          <symbol id="navigation-zoom">
            <polygon points="22 17 15 17 15 21 10 16 15 11 15 15 22 15" />
          </symbol>
          <symbol id="navigation-fullscreen">
            <polygon points="16 22 10 22 10 16 12 16 12 20 16 20" />
            <polygon points="16 10 22 10 22 16 20 16 20 12 16 12" />
          </symbol>
          <symbol id="navigation-arrow_right">
            <polygon points="27 24 19 16 19 32" />
          </symbol>
          <symbol id="legend-total_spend">
            <rect x="0" y="0" width="12" height="12" />
          </symbol>
          <symbol id="legend-total_spend_circle">
            <circle cx="6" cy="6" r="6" />
          </symbol>
          <symbol id="legend-solar_input">
            <rect x="0" y="0" width="12" height="12" />
          </symbol>
          <symbol id="legend-solar_input_circle">
            <circle cx="6" cy="6" r="6" />
          </symbol>
          <symbol id="legend-service_charge">
            <rect x="0" y="0" width="12" height="12" />
          </symbol>
          <symbol id="legend-service_charge_circle">
            <circle cx="6" cy="6" r="6" />
          </symbol>
          <symbol id="legend-average_spend">
            <circle cx="6" cy="6" r="6" />
          </symbol>
          <symbol id="legend-budget">
            <rect x="0" y="5" width="12" height="2" />
          </symbol>
          <symbol id="legend-budget_circle">
            <circle cx="6" cy="6" r="6" />
          </symbol>
          <symbol id="trend-down">
            <line stroke="white" strokeWidth="4" />
          </symbol>
        </defs>
      </svg>
    </div>
  );
}
