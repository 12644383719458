import React from "react";
import Legal from "../Legal";
import Navigation from "../Navigation";
import styles from "./SignupAlreadyCompleted.module.scss";
import personSittingImage from "../../assets/images/daytime-saver/SEN0645_Person_Sitting.png";
import { useParams } from "react-router-dom";
import { useTrackPageVisit } from "../../hooks/useTrackPageVisit";

const SignupAlreadyCompleted: React.FC = (props) => {
  const { accountId } = useParams();
  useTrackPageVisit("signup_already_completed", accountId as string);
  return (
    <div className={styles.signupAlreadyCompletedContainer}>
      <Navigation />
      <div className={styles.content}>
        <div className={styles.left}>
          <img src={personSittingImage} alt="signup already completed" />
        </div>
        <div className={styles.right}>
          <h1 className={styles.title}>Sorry</h1>
          <h2>
            This email address has already registered for the Happy Hours
            program.
          </h2>
        </div>
      </div>
      <Legal accountId={accountId} hideSettings={true} />
    </div>
  );
};

export default SignupAlreadyCompleted;
