import React from "react";
import styles from "./ChartLegend.module.scss";
import { Customer } from "trust-insights-types";

interface ChartLegendProps {
  isWeekView: boolean;
  isDayView: boolean;
  hasSolar: boolean;
  customerData: Customer;
}

const ChartLegend: React.FC<ChartLegendProps> = (props) => {
  const { isWeekView, hasSolar, isDayView } = props;

  return (
    <div
      data-testid="chart-legend"
      className={`${styles.legend} ${styles.isExpanded} ${styles.staticChartLegend}`}
    >
      <div className={styles.legendTag}>
        <div className={styles.legendTitle}>
          <svg viewBox="0 0 12 12" width="12" height="12">
            <use xlinkHref="#legend-total_spend_circle" />
          </svg>
          <span>Usage</span>
        </div>
        <div className={styles.legendContent}>
          {" "}
          Usage is your actual electricity usage.
        </div>
      </div>
      {isWeekView && (
        <div className={styles.legendTag}>
          <div className={styles.legendTitle}>
            <svg viewBox="0 0 12 12" width="12" height="12">
              <use xlinkHref="#legend-service_charge_circle" />
            </svg>
            <span>Supply</span>
          </div>
          <div className={styles.legendContent}>
            {" "}
            Supply charge is a fixed amount for supplying electricity to your
            premises.
          </div>
        </div>
      )}
      {hasSolar && (
        <div className={styles.legendTag}>
          <div className={styles.legendTitle}>
            <svg viewBox="0 0 12 12" width="12" height="12">
              <use xlinkHref="#legend-solar_input_circle" />
            </svg>
            <span>Solar Export</span>
          </div>
          <div className={styles.legendContent}>
            {" "}
            Solar export input indicates the energy you feed into the
            electricity grid (feed-in payments).
          </div>
        </div>
      )}
      <div className={styles.legendTag}>
        <div className={styles.legendTitle}>
          <svg viewBox="0 0 12 12" width="12" height="12">
            <use xlinkHref="#legend-average_spend" />
          </svg>
          <span>Average</span>
        </div>
        <div className={styles.legendContent}>
          {isWeekView ? (
            <>
              {" "}
              Average is calculated using the usages for that day of the week
              over the last 5 weeks.
            </>
          ) : (
            <>
              {" "}
              Average is calculated using the usages for that interval of that
              day of the week over the last 5 weeks.
            </>
          )}
        </div>
      </div>
      {isWeekView ? (
        <div className={styles.legendTag}>
          <div className={styles.legendTitle}>
            <svg viewBox="0 0 12 12" width="12" height="12">
              <use xlinkHref="#legend-budget_circle" />
            </svg>
            <span>Budget</span>
          </div>
          <div className={styles.legendContent}>
            {" "}
            Budget can be compared to your actual spend (weekly budget). Tracker
            emails update on how you’re tracking against your budget.
          </div>
        </div>
      ) : null}
      {isDayView && (
        <p className={styles.infoText}>
          <span><img src="/static/icons/info-icon.svg" alt="tracker usage times" /></span>
          <span>Tracker usage times are in Australian Eastern Standard Time (AEST).</span>
        </p>
      )}
    </div>
  );
};

export default ChartLegend;
