import React from "react";
import WeekOverview from "./WeekOverview";
import DayOverview from "./DayOverview";
import OverviewProps from "../interfaces/OverviewProps";
import styles from "./UsageOverview.module.scss";

const UsageOverview: React.FC<OverviewProps> = (props) => {
  return (
    <div className={styles.overview}>
      <div className={styles.content}>
        <div className={styles.itemFullLine}>
          <h2>Tracker</h2>
        </div>
        {props.isWeekView ? (
          <WeekOverview {...props} />
        ) : props.isDayView ? (
          <DayOverview {...props} />
        ) : null}
      </div>
    </div>
  );
};

export default UsageOverview;
