import React from "react";
import { useEffect, useState, useCallback } from "react";
import styles from "./Loading.module.scss";

interface LoadingProps {
  registerListener: (func: (state: boolean) => void) => void;
  clearListener: () => void;
}

const Loading: React.FC<LoadingProps> = (props) => {
  const { registerListener } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleLoading = useCallback(function handleLoading(state: boolean) {
    setIsLoading(state);
  }, []);

  useEffect(() => {
    registerListener(handleLoading);
  }, [registerListener, handleLoading]);

  return (
    <div className={`${styles.loading} ${isLoading ? styles.isLoading : ""}`}>
      <div className={styles.circleAnimation}></div>
    </div>
  );
};

export default Loading;
