import moment from 'moment'

export function dollars(value: number | null | undefined, places: number = 2): string | undefined {
  if (!value && value !== 0) return
  if (places === 2 && window.Intl) {
    return Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(value)
  }
  let isNeg = value < 0
  value = Math.abs(value)
  let num = value.toFixed(places).replace(/./g, (c, i, a) => {
    return i && c !== '.' && ((a.length - i) % 3 === 0) ? ',' + c : c
  })
  return (isNeg ? '-$' : '$') + num
}

export function wholeNumber(value: number | null | undefined, rounding: string = 'up'): number | undefined {
  if (!value) return
  if (rounding === 'up') {
    return Math.ceil(value)
  }
  if (rounding === 'down') {
    return Math.floor(value)
  }
}

export function abbreviate(value: string, spaces: number = 3): string | undefined {
  if (!value || value.length === 0) return
  return value.substring(0, spaces)
}

export function times(value: number, multiplier: number = 1): number | undefined {
  if (!value) return
  return value * multiplier
}

export function replace(value: string, thing1: string, thing2: string): string | undefined {
  if (!value || value.length === 0) return
  return value.replace(thing1, thing2)
}

export function round(value: number, places: number = 2): string | undefined {
  if (!value && value !== 0) return
  if (window.Intl) {
    return new Intl.NumberFormat('en-AU',
      {
        style: 'decimal',
        minimumFractionDigits: places,
        maximumFractionDigits: places
      }
    ).format(value)
  }
  let isNeg = value < 0
  value = Math.abs(value)
  let num = value.toFixed(places).replace(/./g, (c, i, a) => {
    return i && c !== '.' && ((a.length - i) % 3 === 0) ? ',' + c : c
  })
  return (isNeg ? '-' : '') + num
}

export function formatDate(value: string, format: string): string | undefined {
  if (value) {
    return moment(String(value)).format(format)
  }
}

export function defaultVal(value: any, defaultVal: any): any {
  if (value) {
    return defaultVal
  }
}