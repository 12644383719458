import { DailyIntervalUsageCollection } from "trust-insights-types";
import { WeeklyUsageIntervals } from "trust-insights-types";

export default function IntervalTransformer(input: WeeklyUsageIntervals | DailyIntervalUsageCollection, budget: number | null = null): void {
  let maxPos = 0;
  let maxNeg = 0;

  const maxMin = (total: number) => {
    if (total >= 0 && Math.abs(total) > maxPos) {
      maxPos = Math.abs(total);
    }
    if (total < 0 && Math.abs(total) > maxNeg) {
      maxNeg = Math.abs(total);
    }
  };

  let dailyBudget = budget ? (budget / 7) : false;
  if (dailyBudget) maxMin(dailyBudget);

  // let count = 0;
  for (let i in input) {
    let data = (input as any)[i].data;
    if (data.total_spend) maxMin(data.total_spend);
    if (data.average_spend) maxMin(data.average_spend);
    if (data.service_charge) maxMin(data.service_charge);
    if (data.solar_input) maxMin(data.solar_input * -1);
    if (data.grid_spend) maxMin(data.grid_spend + data.service_charge);
    if (maxPos < data.service_charge * 3) {
      maxPos = data.service_charge * 3;
    }
    // count += 1;
  }

  // if (count <= 7) {
  //   maxPos = Math.ceil(maxPos / 5) * 5;
  //   maxNeg = Math.ceil(maxNeg / 5) * 5;
  // }

  let totalHeight = maxPos + maxNeg;
  if (totalHeight > 0) {
    let multiplier = 100 / totalHeight;
    let zeroPoint = 100 - (multiplier * maxNeg);

    for (let i in input) {
      let data = (input as any)[i].data;
      let height: number;
      let y: number;
      if (data.total_spend) {
        height = Math.abs(data.total_spend) * multiplier;
        y = 0;
        if (data.total_spend > 0) {
          y = zeroPoint - height;
        }
        if (data.total_spend <= 0) {
          y = zeroPoint;
        }
        (input as any)[i].data._total_spend_column = {
          height: height,
          y: y
        };
      }
      if (Math.abs(data.average_spend)) {
        height = data.average_spend * multiplier;
        y = zeroPoint - height;
        (input as any)[i].data._average_spend_column = {
          height: height,
          y: y
        };
      }
      if (Math.abs(data.service_charge)) {
        height = Math.abs(data.service_charge) * multiplier;
        y = zeroPoint - height;
        (input as any)[i].data._service_charge_column = {
          height: height,
          y: y
        };
      }
      if (dailyBudget && Math.abs(dailyBudget)) {
        height = Math.abs(dailyBudget) * multiplier;
        y = zeroPoint - height;
        (input as any)[i].data._budget_column = {
          height: height,
          y: y
        }
      }
      if (Math.abs(data.solar_input)) {
        height = Math.abs(data.solar_input) * multiplier;
        y = zeroPoint;
        (input as any)[i].data._solar_input_column = {
          height: height,
          y: y
        };
      }
      if (Math.abs(data.grid_spend)) {
        height = Math.abs(data.grid_spend) * multiplier;
        y = zeroPoint - height;
        if (Math.abs(data.service_charge)) {
          y = y - (input as any)[i].data._service_charge_column.height;
        }
        (input as any)[i].data._grid_spend_column = {
          height: height,
          y: y
        };
      }
    }
  }

  // Get max spend
  let maxTotal = 0;
  for (let i in input) {
    let data = (input as any)[i].data;
    if (data.total_spend && data.total_spend > maxTotal) {
      maxTotal = data.total_spend
    }
  }
  // Add flag to maximum spend
  let maxIsSet = false;
  for (let i in input) {
    (input as any)[i].data._isMax = false
    if ((input as any)[i].data.total_spend === maxTotal && !maxIsSet) {
      (input as any)[i].data._isMax = true
      maxIsSet = true
    }
  }
}