const config = {
  apiURL: process.env.REACT_APP_API_URL || "",
  client_code: "SIMPLY",
  reduceAndRewardUrl:
    (process.env.REACT_APP_API_URL || "").replace(/\/api$/, "") +
    "/reduce_and_reward",
  innovativeBillingUrl:
    (process.env.REACT_APP_API_URL || "").replace(/\/api$/, "") +
    "/innovative_billing",
};

export { config };
