import React from "react";
import OverviewProps from "../interfaces/OverviewProps";
import { dollars, wholeNumber, round } from "../services/Filters";
import styles from "./WeekOverview.module.scss";

function toAnchor(id: string) {
  const top = (document.getElementById(id) as HTMLElement).offsetTop;
  window.scrollTo(0, top);
}

interface WeeklyInsightsData {
  solar_curtailment?: {
    applicable: boolean;
    welcome_banner: boolean;
  };
}

function showSolarCurtailmentInsights(weeklyInsightsData: WeeklyInsightsData) {
  return (
    weeklyInsightsData?.solar_curtailment?.applicable === true &&
    weeklyInsightsData?.solar_curtailment?.welcome_banner === false
  );
}

const WeekOverview: React.FC<OverviewProps> = (props) => {
  return (
    <>
      {!showSolarCurtailmentInsights(
        props.weeklyInsightsData as WeeklyInsightsData
      ) && (
        <div className={styles.item}>
          {props.weeklyInsightsData ? (
            <div className={styles.itemRight}>
              <img
                src="/static/icons/overview-biggest-engie.svg"
                alt="biggest day this week"
              />
            </div>
          ) : null}
          <div className={styles.itemLeft}>
            {props.weeklyInsightsData?.max_spend?.day &&
            props.weeklyInsightsData?.max_spend?.value ? (
              <div>
                <h5 className={styles.title}>
                  {props.weeklyInsightsData.max_spend.day}
                </h5>
                <span>
                  Was your biggest day this week (
                  {dollars(props.weeklyInsightsData.max_spend.value)})
                </span>
              </div>
            ) : props.weeklyInsightsData ? (
              <div>
                <h5 className={styles.title}>--</h5>
                <span>No biggest day data for this week</span>
              </div>
            ) : null}
          </div>
        </div>
      )}

      <div className={styles.item}>
        {props.weeklyInsightsData?.weekly_average_spend ? (
          <div className={styles.itemRight}>
            {props.weeklyInsightsData.trend.down ? (
              <img
                src="/static/icons/overview-arrow-down-engie.svg"
                alt="lower than your average week"
              />
            ) : props.weeklyInsightsData.trend.up ? (
              <img
                src="/static/icons/overview-arrow-up-engie.svg"
                alt="higher than your average week"
              />
            ) : null}
          </div>
        ) : null}
        <div className={styles.itemLeft}>
          {props.weeklyInsightsData ? (
            <div>
              <h5 className={styles.title}>
                {props.weeklyInsightsData.trend.down
                  ? `${wholeNumber(
                      Math.abs(props.weeklyInsightsData.trend.value_to_times),
                      "down"
                    )}% Lower`
                  : props.weeklyInsightsData.trend.up
                  ? `${wholeNumber(
                      props.weeklyInsightsData.trend.value_to_times
                    )}% Higher`
                  : props.weeklyInsightsData.trend.stable
                  ? `${wholeNumber(
                      Math.abs(props.weeklyInsightsData.trend.value_to_times)
                    )}%`
                  : null}
              </h5>
              <span>Than your average week</span>
            </div>
          ) : null}
        </div>
      </div>

      <div className={styles.item}>
        {props.weeklyInsightsData &&
        props.weeklyInsightsData.weekly_average_spend ? (
          <div className={styles.itemRight}>
            <img
              src="/static/icons/overview-currency-symbol-engie.svg"
              alt="average weekly spend over previous 4 weeks"
            />
          </div>
        ) : null}
        <div className={styles.itemLeft}>
          {props.weeklyInsightsData &&
          props.weeklyInsightsData.weekly_average_spend ? (
            <div>
              <h5 className={styles.title}>
                {dollars(
                  wholeNumber(props.weeklyInsightsData.weekly_average_spend),
                  0
                )}
              </h5>
              <span>
                Was your average weekly spend over previous 4 weeks.{" "}
                <button
                  className={styles.link}
                  onClick={() => toAnchor("budget")}
                >
                  Edit budget
                </button>
              </span>
            </div>
          ) : (
            <div>
              <h5 className={styles.title}>--</h5>
              <span>No average weekly spend data</span>
            </div>
          )}
        </div>
      </div>
      <div className={styles.item}>
        {props.weeklyInsightsData &&
          props.weeklyInsightsData.weekly_average_spend && (
            <div className={styles.itemRight}>
              <img
                src="/static/icons/overview-currency-symbol-engie.svg"
                alt="total spend over this week"
              />
            </div>
          )}
        <div className={styles.itemLeft}>
          {props.weeklyInsightsData ? (
            <div>
              <h5 className={styles.title}>
                {dollars(props.weeklyInsightsData._total_spend, 2)}
              </h5>
              <span>Was your total spend over this week</span>
            </div>
          ) : (
            <div>
              <h5 className={styles.title}>--</h5>
              <span>No average weekly spend data</span>
            </div>
          )}
        </div>
      </div>
      {/* SOLAR */}
      {props.hasSolar ? (
        <div className={styles.item}>
          <div className={styles.itemRight}>
            <img
              src="/static/icons/overview-wallet-engie.svg"
              alt="feed-in credit earnt"
            />
          </div>
          <div className={styles.itemLeft}>
            {props.weeklyInsightsData &&
            props.weeklyInsightsData.solar_export ? (
              <div>
                <h5 className={styles.title}>
                  {dollars(
                    wholeNumber(props.weeklyInsightsData.solar_export, "down"),
                    0
                  )}
                </h5>
                <span>Feed-in credit earnt</span>
              </div>
            ) : props.weeklyInsightsData._total_solar === 0 ? (
              <div>
                <h5 className={styles.title}>$0</h5>
                <span>No solar export data for this week</span>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
      {props.hasSolar ? (
        <div className={styles.item}>
          <div className={styles.itemRight}>
            <img
              src="/static/icons/overview-right-arrow-engie.svg"
              alt="electricity exported last week"
            />
          </div>
          <div className={styles.itemLeft}>
            {props.weeklyInsightsData &&
            props.weeklyInsightsData.solar_export_kwh ? (
              <div>
                <h5 className={styles.title}>
                  {round(props.weeklyInsightsData.solar_export_kwh, 0)} kWh
                </h5>
                <span>Of electricity exported last week</span>
              </div>
            ) : props.weeklyInsightsData._total_solar === 0 ? (
              <div>
                <h5 className={styles.title}>0</h5>
                <span>No solar export data for this week</span>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
      {/* END SOLAR */}

      {/* Solar curtailment */}
      {showSolarCurtailmentInsights(
        props.weeklyInsightsData as WeeklyInsightsData
      ) && (
        <>
          {props.weeklyInsightsData?.solar_curtailment
            ?.cumulative_hours_curtailed >= 0 ? (
            <div className={styles.item}>
              <div className={styles.itemRight}>
                <img
                  src="/static/icons/overview-solar-curtailment.svg"
                  alt="hours solar export withheld under Solar Advantage trial"
                />
              </div>
              <div className={styles.itemLeft}>
                <div>
                  <h5 className={styles.title}>
                    {Math.round(
                      props.weeklyInsightsData.solar_curtailment
                        .cumulative_hours_curtailed / 0.5
                    ) * 0.5}{" "}
                    hours’
                  </h5>
                  <span>
                    Worth of solar export management under Solar Advantage trial
                  </span>
                </div>
              </div>
            </div>
          ) : null}

          {props.weeklyInsightsData?.solar_curtailment
            ?.cumulative_kwh_curtailed >= 0 ? (
            <div className={styles.item}>
              <div className={styles.itemRight}>
                <img
                  src="/static/icons/overview-solar-curtailment.svg"
                  alt="kWh solar export withheld under Solar Advantage trial"
                />
              </div>
              <div className={styles.itemLeft}>
                <div>
                  <h5 className={styles.title}>
                    {round(
                      props.weeklyInsightsData.solar_curtailment
                        .cumulative_kwh_curtailed,
                      2
                    )}{" "}
                    kWh
                  </h5>
                  <span>
                    Worth of solar export management under Solar Advantage trial
                  </span>
                </div>
              </div>
            </div>
          ) : null}

          {props.weeklyInsightsData?.solar_curtailment?.cumulative_benefit >= 0 ? (
            <div className={styles.item}>
              <div className={styles.itemRight}>
                <img
                  src="/static/icons/overview-wallet-engie.svg"
                  alt="Extra benefit earned under Solar Advantage trial"
                />
              </div>

              <div className={styles.itemLeft}>
                <div>
                  <h5 className={styles.title}>
                    {dollars(
                      props.weeklyInsightsData.solar_curtailment
                        .cumulative_benefit
                    )}
                  </h5>
                  <span>Extra benefit earned under Solar Advantage trial</span>
                </div>
              </div>
            </div>
          ) : null}
        </>
      )}

      {/* END solar curtailment */}
    </>
  );
};

export default WeekOverview;
