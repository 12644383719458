import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Navigation from "./Navigation";
import styles from "./ResidentialSurvey.module.scss";
import axios from "axios";
import { useTrackPageVisit } from "../hooks/useTrackPageVisit";
import { useRollbar } from "@rollbar/react";
import { config } from "../config";

interface ResidentialSurveyProps {
  onAlert: (message: string, type: string) => void;
  onLoadingChange: (loading: boolean) => void;
}

interface ResidentialSurveyState {
  [key: string]: any;
}

const serverUrl = config.apiURL;

const ResidentialSurvey: React.FC<ResidentialSurveyProps> = (props) => {
  const { accountId, from } = useParams();
  const navigate = useNavigate();
  const { onAlert, onLoadingChange } = props;
  const rollbar = useRollbar();
  useTrackPageVisit("survey", accountId as string);

  const [state, setState] = useState<ResidentialSurveyState>({
    tenancy: null,
    building_type: null,
    bedrooms_qty: null,
    household_size: null,
    heating_type: [],
    hot_water_type: null,
    aircon_qty: null,
    pool_or_spa: [],
    solar_panels: null,
    solar_panels_size: undefined,
    home_battery: null,
    home_battery_size: undefined,
    electric_car: null,
    load_disag: [],
  });

  function showLoading() {
    onLoadingChange(true);
  }

  function hideLoading() {
    onLoadingChange(false);
  }

  function checkForm() {
    if (
      state.tenancy &&
      state.building_type &&
      state.bedrooms_qty &&
      state.household_size &&
      state.heating_type.length !== 0 &&
      state.hot_water_type &&
      state.aircon_qty &&
      state.pool_or_spa.length !== 0 &&
      (state.solar_panels || state.solar_panels_size) &&
      (state.home_battery || state.home_battery_size) &&
      state.electric_car &&
      state.load_disag.length !== 0
    ) {
      return true;
    }

    onAlert("Please answer all the questions", "warning");

    return false;
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    let pass = checkForm();

    if (pass !== true) {
      return;
    }

    let profile: { [key: string]: any } = {
      tenancy: state.tenancy,
      building_type: state.building_type,
      bedrooms_qty: state.bedrooms_qty,
      household_size: state.household_size,
      heating_type: state.heating_type,
      hot_water_type: state.hot_water_type,
      aircon_qty: state.aircon_qty,
      pool_or_spa: state.pool_or_spa,
      solar_panels: state.solar_panels,
      solar_panels_size: state.solar_panels_size,
      home_battery: state.home_battery,
      home_battery_size: state.home_battery_size,
      electric_car: state.electric_car,
      load_disag: state.load_disag,
    };
    // remove blank or null keys
    Object.keys(profile).forEach(
      (key) =>
        (profile[key] === "" ||
          profile[key] === null ||
          profile[key] === undefined) &&
        delete profile[key]
    );
    save(profile);
  }

  function save(profile: ResidentialSurveyState) {
    showLoading();
    axios({
      method: "patch",
      baseURL: serverUrl,
      url: "/api/accounts/update_profile",
      params: { account_id: accountId },
      data: { profile: profile, source: "survey" },
    })
      .then(() => {
        hideLoading();
        onAlert("Thank you for your time.", "warning");
        from
          ? navigate(`/new/usage/${accountId}/${from}`)
          : navigate(`/new/usage/${accountId}`);
      })
      .catch((e) => saveError(e));
  }

  function saveError(e: Error) {
    hideLoading();
    onAlert("Error saving your responses. Please try again.", "warning");
    rollbar.error("Ajax request error (save survey)", e);
  }

  function uncheck(id: string, stateKey: string) {
    const skip = document.getElementById(id) as HTMLInputElement;
    const skipValue = skip.value;
    const validStateKeys = ["load_disag", "heating_type", "pool_or_spa"];
    if (validStateKeys.includes(stateKey)) {
      setState((prevState) => ({ ...prevState, [stateKey]: [skipValue] }));
    } else {
      throw new Error(`invalid state key '${stateKey}' provided`);
    }
  }

  function skip() {
    from
      ? navigate(`/new/usage/${accountId}/${from}`)
      : navigate(`/new/usage/${accountId}`);
  }

  function onChangeCheckboxSelect(
    item: string,
    stateKey: string,
    unselectValue: string = "skip"
  ) {
    if (state[stateKey].includes(item)) {
      setState((prevState) => ({
        ...prevState,
        [stateKey]: prevState[stateKey].filter(
          (value: string) => value !== item
        ),
      }));
    } else {
      setState((prevState) => {
        return {
          ...prevState,
          [stateKey]: [
            ...prevState[stateKey].filter(
              (value: string) => value !== unselectValue
            ),
            item,
          ],
        };
      });
    }
  }

  function onChangeRadioSelect(
    event: React.FormEvent<HTMLDivElement>,
    stateKey: string
  ) {
    setState((prevState) => ({
      ...prevState,
      [stateKey]: (event.target as HTMLInputElement).value,
    }));
  }

  function onChangeHomeBatterySize(event: React.ChangeEvent<HTMLInputElement>) {
    setState((prevState) => ({
      ...prevState,
      home_battery_size: event.target.value,
    }));
  }

  function onChangeSolarPanelsSize(event: React.ChangeEvent<HTMLInputElement>) {
    setState((prevState) => ({
      ...prevState,
      solar_panels_size: event.target.value,
    }));
  }

  return (
    <div>
      <Navigation />
      <div>
        <form onSubmit={handleSubmit} className={styles.form}>
          <div className={styles.banner}>
            <h2>
              Engie Tracker Survey
              <button className={styles.skip} onClick={skip}>
                Skip
              </button>
            </h2>
            <p>
              Thank you for taking the time to complete our small survey. Your
              answers will help ensure the information we share with you is
              useful and relevant.
            </p>
          </div>
          <ol>
            <li className={styles.surveyQuestion} data-testid="question-one">
              Which of the following appliances are associated with your Engie
              electricity account?{" "}
              <span className={styles.required}>Required</span>{" "}
              <span className={styles.required}>Multi-select</span>
              <div>
                <input
                  type="checkbox"
                  checked={state.load_disag.includes("dishwasher")}
                  readOnly={true}
                  id="q1_1"
                  value="dishwasher"
                  onChange={() =>
                    onChangeCheckboxSelect("dishwasher", "load_disag")
                  }
                />
                <label htmlFor="q1_1">Dishwasher</label>
                <input
                  type="checkbox"
                  checked={state.load_disag.includes("tv")}
                  readOnly={true}
                  id="q1_2"
                  value="tv"
                  onChange={() => onChangeCheckboxSelect("tv", "load_disag")}
                />
                <label htmlFor="q1_2"> TV</label>
                <input
                  type="checkbox"
                  checked={state.load_disag.includes("electric_oven")}
                  readOnly={true}
                  id="q1_3"
                  value="electric_oven"
                  onChange={() =>
                    onChangeCheckboxSelect("electric_oven", "load_disag")
                  }
                />
                <label htmlFor="q1_3">Electric Oven</label>
                <input
                  type="checkbox"
                  checked={state.load_disag.includes("dryer")}
                  readOnly={true}
                  id="q1_4"
                  value="dryer"
                  onChange={() => onChangeCheckboxSelect("dryer", "load_disag")}
                />
                <label htmlFor="q1_4">Dryer</label>
                <input
                  type="checkbox"
                  checked={state.load_disag.includes("washing_machine")}
                  readOnly={true}
                  id="q1_5"
                  value="washing_machine"
                  onChange={() =>
                    onChangeCheckboxSelect("washing_machine", "load_disag")
                  }
                />
                <label htmlFor="q1_5">Washing Machine</label>
                <input
                  type="checkbox"
                  checked={state.load_disag.includes("fridge_freezer")}
                  readOnly={true}
                  id="q1_6"
                  value="fridge_freezer"
                  onChange={() =>
                    onChangeCheckboxSelect("fridge_freezer", "load_disag")
                  }
                />
                <label htmlFor="q1_6">Fridge/Freezer or both</label>
                <input
                  type="checkbox"
                  checked={state.load_disag.includes("electric_hob")}
                  readOnly={true}
                  id="q1_7"
                  value="electric_hob"
                  onChange={() =>
                    onChangeCheckboxSelect("electric_hob", "load_disag")
                  }
                />
                <label htmlFor="q1_7">Electric Hob</label>
                <input
                  type="checkbox"
                  checked={state.load_disag.includes("skip")}
                  readOnly={true}
                  id="q1_8"
                  value="skip"
                  disabled={state.load_disag.length > 0}
                  onChange={() => uncheck("q1_8", "load_disag")}
                />
                <label htmlFor="q1_8">Rather Not Say</label>
              </div>
            </li>
            <li className={styles.surveyQuestion} data-testid="question-two">
              Do you rent or own your home?{" "}
              <span className={styles.required}>Required</span>
              <div
                onChange={(e) => {
                  onChangeRadioSelect(e, "tenancy");
                }}
              >
                <input
                  type="radio"
                  checked={state.tenancy === "rent"}
                  readOnly={true}
                  id="q2_1"
                  value="rent"
                />
                <label htmlFor="q2_1">Rent</label>
                <input
                  type="radio"
                  checked={state.tenancy === "own"}
                  readOnly={true}
                  id="q2_2"
                  value="own"
                />
                <label htmlFor="q2_2">Own</label>
                <input
                  type="radio"
                  checked={state.tenancy === "skip"}
                  readOnly={true}
                  id="q2_3"
                  value="skip"
                />
                <label htmlFor="q2_3">Rather Not Say</label>
              </div>
            </li>
            <li className={styles.surveyQuestion} data-testid="question-three">
              What type of home do you live in?{" "}
              <span className={styles.required}>Required</span>
              <div
                onChange={(e) => {
                  onChangeRadioSelect(e, "building_type");
                }}
              >
                <input
                  type="radio"
                  checked={state.building_type === "apartment"}
                  readOnly={true}
                  id="q3_1"
                  value="apartment"
                />
                <label htmlFor="q3_1">Apartment</label>
                <input
                  type="radio"
                  checked={state.building_type === "house"}
                  readOnly={true}
                  id="q3_2"
                  value="house"
                />
                <label htmlFor="q3_2">House</label>
                <input
                  type="radio"
                  checked={state.building_type === "unit"}
                  readOnly={true}
                  id="q3_3"
                  value="unit"
                />
                <label htmlFor="q3_3">Unit</label>
                <input
                  type="radio"
                  checked={state.building_type === "townhouse"}
                  readOnly={true}
                  id="q3_4"
                  value="townhouse"
                />
                <label htmlFor="q3_4">Townhouse</label>
                <input
                  type="radio"
                  checked={state.building_type === "other"}
                  readOnly={true}
                  id="q3_5"
                  value="other"
                />
                <label htmlFor="q3_5">Other</label>
                <input
                  type="radio"
                  checked={state.building_type === "skip"}
                  readOnly={true}
                  id="q3_6"
                  value="skip"
                />
                <label htmlFor="q3_6">Rather Not Say</label>
              </div>
            </li>

            <li className={styles.surveyQuestion} data-testid="question-four">
              How many bedrooms are there in your home?{" "}
              <span className={styles.required}>Required</span>
              <div
                onChange={(e) => {
                  onChangeRadioSelect(e, "bedrooms_qty");
                }}
              >
                <input
                  type="radio"
                  checked={state.bedrooms_qty === "1"}
                  readOnly={true}
                  id="q4_1"
                  value="1"
                />
                <label htmlFor="q4_1">1</label>
                <input
                  type="radio"
                  checked={state.bedrooms_qty === "2"}
                  readOnly={true}
                  id="q4_2"
                  value="2"
                />
                <label htmlFor="q4_2">2</label>
                <input
                  type="radio"
                  checked={state.bedrooms_qty === "3"}
                  readOnly={true}
                  id="q4_3"
                  value="3"
                />
                <label htmlFor="q4_3">3</label>
                <input
                  type="radio"
                  checked={state.bedrooms_qty === "4"}
                  readOnly={true}
                  id="q4_4"
                  value="4"
                />
                <label htmlFor="q4_4">4</label>
                <input
                  type="radio"
                  checked={state.bedrooms_qty === "5"}
                  readOnly={true}
                  id="q4_5"
                  value="5"
                />
                <label htmlFor="q4_5">5</label>
                <input
                  type="radio"
                  checked={state.bedrooms_qty === "6_and_more"}
                  readOnly={true}
                  id="q4_6"
                  value="6_and_more"
                />
                <label htmlFor="q4_6">6+</label>
                <input
                  type="radio"
                  checked={state.bedrooms_qty === "skip"}
                  readOnly={true}
                  id="q4_7"
                  value="skip"
                />
                <label htmlFor="q4_7">Rather Not Say</label>
              </div>
            </li>
            <li className={styles.surveyQuestion} data-testid="question-five">
              How many people live in your home?{" "}
              <span className={styles.required}>Required</span>
              <div
                onChange={(e) => {
                  onChangeRadioSelect(e, "household_size");
                }}
              >
                <input
                  type="radio"
                  checked={state.household_size === "1"}
                  readOnly={true}
                  id="q5_1"
                  value="1"
                />
                <label htmlFor="q5_1">1</label>
                <input
                  type="radio"
                  checked={state.household_size === "2"}
                  readOnly={true}
                  id="q5_2"
                  value="2"
                />
                <label htmlFor="q5_2">2</label>
                <input
                  type="radio"
                  checked={state.household_size === "3"}
                  readOnly={true}
                  id="q5_3"
                  value="3"
                />
                <label htmlFor="q5_3">3</label>
                <input
                  type="radio"
                  checked={state.household_size === "4"}
                  readOnly={true}
                  id="q5_4"
                  value="4"
                />
                <label htmlFor="q5_4">4</label>
                <input
                  type="radio"
                  checked={state.household_size === "5"}
                  readOnly={true}
                  id="q5_5"
                  value="5"
                />
                <label htmlFor="q5_5">5</label>
                <input
                  type="radio"
                  checked={state.household_size === "6_and_more"}
                  readOnly={true}
                  value="6_and_more"
                />
                <label htmlFor="q5_6">6+</label>
                <input
                  type="radio"
                  checked={state.household_size === "skip"}
                  readOnly={true}
                  id="q5_7"
                  value="skip"
                />
                <label htmlFor="q5_7">Rather Not Say</label>
              </div>
            </li>
            <li className={styles.surveyQuestion} data-testid="question-six">
              How do you heat your home?{" "}
              <span className={styles.required}>Required</span>
              <span className={styles.required}>Multi-select</span>
              <div>
                <input
                  type="checkbox"
                  checked={state.heating_type.includes(
                    "electricity_reverse_cycle"
                  )}
                  readOnly={true}
                  id="q6_1"
                  value="electricity_reverse_cycle"
                  onChange={() =>
                    onChangeCheckboxSelect(
                      "electricity_reverse_cycle",
                      "heating_type"
                    )
                  }
                />
                <label htmlFor="q6_1">Electric reverse cycle</label>
                <input
                  type="checkbox"
                  checked={state.heating_type.includes("gas_space_heating")}
                  readOnly={true}
                  id="q6_2"
                  value="gas_space_heating"
                  onChange={() =>
                    onChangeCheckboxSelect("gas_space_heating", "heating_type")
                  }
                />
                <label htmlFor="q6_2">Gas space heating</label>
                <input
                  type="checkbox"
                  checked={state.heating_type.includes("wood_burner")}
                  readOnly={true}
                  id="q6_3"
                  value="wood_burner"
                  onChange={() =>
                    onChangeCheckboxSelect("wood_burner", "heating_type")
                  }
                />
                <label htmlFor="q6_3">Wood burner</label>
                <input
                  type="checkbox"
                  checked={state.heating_type.includes("electric_heater")}
                  readOnly={true}
                  id="q6_4"
                  value="electric_heater"
                  onChange={() =>
                    onChangeCheckboxSelect("electric_heater", "heating_type")
                  }
                />
                <label htmlFor="q6_4">Electric Heater</label>
                <input
                  type="checkbox"
                  checked={state.heating_type.includes("other")}
                  readOnly={true}
                  id="q6_5"
                  value="other"
                  onChange={() =>
                    onChangeCheckboxSelect("other", "heating_type")
                  }
                />
                <label htmlFor="q6_5">Other</label>
                <input
                  type="checkbox"
                  checked={state.heating_type.includes("skip")}
                  readOnly={true}
                  id="q6_6"
                  value="skip"
                  disabled={state.heating_type.length > 0}
                  onChange={() => uncheck("q6_6", "heating_type")}
                />
                <label htmlFor="q6_6">Rather Not Say</label>
              </div>
            </li>
            <li className={styles.surveyQuestion} data-testid="question-seven">
              How do you heat your hot water?{" "}
              <span className={styles.required}>Required</span>
              <div
                onChange={(e) => {
                  onChangeRadioSelect(e, "hot_water_type");
                }}
              >
                <input
                  type="radio"
                  checked={state.hot_water_type === "electricity"}
                  readOnly={true}
                  id="q7_1"
                  value="electricity"
                />
                <label htmlFor="q7_1">Electricity</label>
                <input
                  type="radio"
                  checked={state.hot_water_type === "gas"}
                  readOnly={true}
                  id="q7_2"
                  value="gas"
                />
                <label htmlFor="q7_2">Gas</label>
                <input
                  type="radio"
                  checked={state.hot_water_type === "solar_electric"}
                  readOnly={true}
                  id="q7_3"
                  value="solar_electric"
                />
                <label htmlFor="q7_3">Solar Electric</label>
                <input
                  type="radio"
                  checked={state.hot_water_type === "skip"}
                  readOnly={true}
                  id="q7_4"
                  value="skip"
                />
                <label htmlFor="q7_4">Rather Not Say</label>
              </div>
            </li>

            <li className={styles.surveyQuestion} data-testid="question-eight">
              How many air-conditioning units are in your home?{" "}
              <span className={styles.required}>Required</span>
              <div
                onChange={(e) => {
                  onChangeRadioSelect(e, "aircon_qty");
                }}
              >
                <input
                  type="radio"
                  checked={state.aircon_qty === "0"}
                  readOnly={true}
                  id="q8_1"
                  value="0"
                />
                <label htmlFor="q8_1">0</label>
                <input
                  type="radio"
                  checked={state.aircon_qty === "1"}
                  readOnly={true}
                  id="q8_2"
                  value="1"
                />
                <label htmlFor="q8_2">1</label>
                <input
                  type="radio"
                  checked={state.aircon_qty === "2"}
                  readOnly={true}
                  id="q8_3"
                  value="2"
                />
                <label htmlFor="q8_3">2</label>
                <input
                  type="radio"
                  checked={state.aircon_qty === "3"}
                  readOnly={true}
                  id="q8_4"
                  value="3"
                />
                <label htmlFor="q8_4">3</label>
                <input
                  type="radio"
                  checked={state.aircon_qty === "4"}
                  readOnly={true}
                  id="q8_5"
                  value="4"
                />
                <label htmlFor="q8_5">4</label>
                <input
                  type="radio"
                  checked={state.aircon_qty === "5"}
                  readOnly={true}
                  id="q8_6"
                  value="5"
                />
                <label htmlFor="q8_6">5</label>
                <input
                  type="radio"
                  checked={state.aircon_qty === "6_and_more"}
                  readOnly={true}
                  id="q8_7"
                  value="6_and_more"
                />
                <label htmlFor="q8_7">6+</label>
                <input
                  type="radio"
                  checked={state.aircon_qty === "skip"}
                  readOnly={true}
                  id="q8_8"
                  value="skip"
                />
                <label htmlFor="q8_8">Rather Not Say</label>
              </div>
            </li>

            <li className={styles.surveyQuestion} data-testid="question-nine">
              Do you have a swimming pool/spa or other timed load such as slab
              heating? <span className={styles.required}>Required</span>{" "}
              <span className={styles.required}>Multi-select</span>
              <div>
                <input
                  type="checkbox"
                  checked={state.pool_or_spa.includes("no")}
                  readOnly={true}
                  id="q9_1"
                  value="no"
                  disabled={state.pool_or_spa.length > 0}
                  onChange={() => uncheck("q9_1", "pool_or_spa")}
                />
                <label htmlFor="q9_1">No</label>
                <input
                  type="checkbox"
                  checked={state.pool_or_spa.includes("pool_pump")}
                  readOnly={true}
                  id="q9_2"
                  value="pool_pump"
                  onChange={() =>
                    onChangeCheckboxSelect("pool_pump", "pool_or_spa", "no")
                  }
                />
                <label htmlFor="q9_2">Pool Pump</label>
                <input
                  type="checkbox"
                  checked={state.pool_or_spa.includes("spa")}
                  readOnly={true}
                  id="q9_3"
                  value="spa"
                  onChange={() =>
                    onChangeCheckboxSelect("spa", "pool_or_spa", "no")
                  }
                />
                <label htmlFor="q9_3">Spa Pool</label>
                <input
                  type="checkbox"
                  checked={state.pool_or_spa.includes("pool_heater")}
                  readOnly={true}
                  id="q9_4"
                  value="pool_heater"
                  onChange={() =>
                    onChangeCheckboxSelect("pool_heater", "pool_or_spa", "no")
                  }
                />
                <label htmlFor="q9_4">Pool Heater</label>
                <input
                  type="checkbox"
                  checked={state.pool_or_spa.includes("underfloor_heating")}
                  readOnly={true}
                  id="q9_5"
                  value="underfloor_heating"
                  onChange={() =>
                    onChangeCheckboxSelect(
                      "underfloor_heating",
                      "pool_or_spa",
                      "no"
                    )
                  }
                />
                <label htmlFor="q9_5">Underfloor Heating</label>
                <input
                  type="checkbox"
                  checked={state.pool_or_spa.includes("other")}
                  readOnly={true}
                  id="q9_6"
                  value="other"
                  onChange={() =>
                    onChangeCheckboxSelect("other", "pool_or_spa", "no")
                  }
                />
                <label htmlFor="q9_6">Other</label>
              </div>
            </li>

            <li className={styles.surveyQuestion} data-testid="question-ten-a">
              Do you have solar PV (electric) panels on your roof?{" "}
              <span className={styles.required}>Required</span>
              <div
                onChange={(e) => {
                  onChangeRadioSelect(e, "solar_panels");
                }}
              >
                <input
                  type="radio"
                  checked={state.solar_panels === "no"}
                  readOnly={true}
                  id="q10_1"
                  value="no"
                />
                <label htmlFor="q10_1">No</label>
                <input
                  type="radio"
                  checked={state.solar_panels === "three_kW"}
                  readOnly={true}
                  id="q10_2"
                  value="three_kW"
                />
                <label htmlFor="q10_2">3kW</label>
                <input
                  type="radio"
                  checked={state.solar_panels === "four_to_six_kW"}
                  readOnly={true}
                  id="q10_3"
                  value="four_to_six_kW"
                />
                <label htmlFor="q10_3">4-6KW</label>
                <input
                  type="radio"
                  checked={state.solar_panels === "seven_to_ten_kW"}
                  readOnly={true}
                  id="q10_4"
                  value="seven_to_ten_kW"
                />
                <label htmlFor="q10_4">7-10KW</label>
                <input
                  type="radio"
                  checked={state.solar_panels === "greater_than_ten_kW"}
                  readOnly={true}
                  id="q10_5"
                  value="greater_than_ten_kW"
                />
                <label htmlFor="q10_5">10+KW</label>
                <input
                  type="radio"
                  checked={state.solar_panels === "skip"}
                  readOnly={true}
                  id="q10_6"
                  value="skip"
                />
                <label htmlFor="q10_6">Rather Not Say</label>
              </div>
            </li>

            <div data-testid="question-ten-b">
              If you know the exact size of your system please enter it here.
              i.e 4.6kW?
              <div className={styles.itemFull}>
                <label htmlFor="q10_7">
                  <input
                    type="number"
                    min="0"
                    max="1000"
                    step=".01"
                    id="q10_7"
                    autoComplete="off"
                    value={state.solar_panels_size ?? ""}
                    onChange={onChangeSolarPanelsSize}
                  />
                  <span className={styles.kw}>kW</span>
                </label>
              </div>
            </div>
            <li
              className={styles.surveyQuestion}
              data-testid="question-eleven-a"
            >
              Do you have a home battery?{" "}
              <span className={styles.required}>Required</span>
              <div
                onChange={(e) => {
                  onChangeRadioSelect(e, "home_battery");
                }}
              >
                <input
                  type="radio"
                  checked={state.home_battery === "no"}
                  readOnly={true}
                  id="q11_1"
                  value="no"
                />
                <label htmlFor="q11_1">No</label>
                <input
                  type="radio"
                  checked={state.home_battery === "yes"}
                  readOnly={true}
                  id="q11_2"
                  value="yes"
                />
                <label htmlFor="q11_2">Yes</label>
                <input
                  type="radio"
                  checked={state.home_battery === "skip"}
                  readOnly={true}
                  id="q11_3"
                  value="skip"
                />
                <label htmlFor="q11_3">Rather Not Say</label>
              </div>
            </li>

            <div data-testid="question-eleven-b">
              If yes, what size is the battery system i.e. 12.4 kWh?
              <div className={styles.itemFull}>
                <label htmlFor="q11_4">
                  <input
                    type="number"
                    min="0"
                    max="1000"
                    step=".01"
                    id="q1_4"
                    autoComplete="off"
                    value={state.home_battery_size ?? ""}
                    onChange={onChangeHomeBatterySize}
                  />
                  <span className={styles.kw}>kWh</span>
                </label>
              </div>
            </div>

            <li className={styles.surveyQuestion} data-testid="question-twelve">
              Do you have an Electric Car, what size is your battery?{" "}
              <span className={styles.required}>Required</span>
              <div
                onChange={(e) => {
                  onChangeRadioSelect(e, "electric_car");
                }}
              >
                <input
                  type="radio"
                  checked={state.electric_car === "no"}
                  readOnly={true}
                  id="q12_1"
                  value="no"
                />
                <label htmlFor="q12_1">No</label>
                <input
                  type="radio"
                  checked={state.electric_car === "less_than_forty_kW"}
                  readOnly={true}
                  id="q12_2"
                  value="less_than_forty_kW"
                />
                <label htmlFor="q12_2"> &lt;40kWh</label>
                <input
                  type="radio"
                  checked={state.electric_car === "forty_to_sixty_kWh"}
                  readOnly={true}
                  id="q12_3"
                  value="forty_to_sixty_kWh"
                />
                <label htmlFor="q12_3">40-60kWh</label>
                <input
                  type="radio"
                  checked={state.electric_car === "greater_than_sixty_kW"}
                  readOnly={true}
                  id="q12_4"
                  value="greater_than_sixty_kW"
                />
                <label htmlFor="q12_4">&gt;60kWh</label>
                <input
                  type="radio"
                  checked={state.electric_car === "skip"}
                  readOnly={true}
                  id="q12_5"
                  value="skip"
                />
                <label htmlFor="q12_5">Rather Not Say</label>
              </div>
            </li>
          </ol>
          <button type="submit" className={styles.submit}>
            Submit
          </button>
        </form>
      </div>
      <footer>
        <br />
        <br />
      </footer>
    </div>
  );
};

export default ResidentialSurvey;
