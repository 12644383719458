import React from "react";
import styles from "./RewardsOverview.module.scss";
import { dollars } from "../../services/Filters";
import { ReduceAndRewardRewards } from "trust-insights-types";

interface RewardsOverviewProps {
  rewards: ReduceAndRewardRewards | null;
  totalRewards: ReduceAndRewardRewards | null;
  showRank: boolean;
}

const RewardsOverview: React.FC<RewardsOverviewProps> = (props) => {
  let { rewards, totalRewards, showRank } = props;

  return (
    <div className={styles.rewardsOverview}>
      <div className={styles.content}>
        <div className={styles.itemFullLine}>
          <h2>Event Portal</h2>
          <p>
            Welcome to your Reduce &amp; Reward event portal. Check out how you
            performed in past events.
          </p>
        </div>
        {/* Bill credit */}
        {showRank ? (
          <div className={styles.items}>
            <div className={styles.itemsCenter}>
              {rewards ? (
                <div className={styles.itemRight}>
                  <img
                    src="/static/icons/overview-currency-symbol.svg"
                    alt="overview currency symbol"
                  />
                </div>
              ) : null}
              <div className={styles.itemLeft}>
                {rewards && rewards.cents ? (
                  <div>
                    <h5 className={`${styles.title} ${styles.textBlue}`}>
                      {dollars(rewards.cents / 100, 0)}
                    </h5>
                    <span className={styles.text}>
                      Total bill credits earned
                    </span>
                  </div>
                ) : rewards ? (
                  <div>
                    <h5 className={`${styles.title} ${styles.textBlue}`}>$0</h5>
                    <span className={styles.text}>
                      Total bill credits earned
                    </span>
                  </div>
                ) : (
                  <div>{/* Show nothing */}</div>
                )}
              </div>
            </div>
            <div className={styles.itemsCenter}>
              {rewards && (
                <div className={styles.itemRight}>
                  {rewards.cents ? (
                    <div>
                      {rewards.cents === 1000 ? (
                        <img
                          src="/static/icons/silver-icon.svg"
                          alt="silver icon"
                        />
                      ) : rewards.cents >= 10000 ? (
                        <img
                          src="/static/icons/gold-icon.svg"
                          alt="gold icon"
                        />
                      ) : null}
                    </div>
                  ) : rewards ? (
                    <div>
                      <img
                        src="/static/icons/bronze-icon.svg"
                        alt="bronze icon"
                      />
                    </div>
                  ) : null}
                </div>
              )}
              <div className={styles.itemLeft}>
                {rewards && rewards.cents ? (
                  <div>
                    {rewards.cents === 1000 ? (
                      <div>
                        <h5 className={`${styles.title} ${styles.textBlue}`}>
                          Silver Saver
                        </h5>
                        <span className={styles.text}>
                          Reduced by 30% or more below your baseline
                        </span>
                      </div>
                    ) : rewards.cents >= 10000 ? (
                      <div>
                        <h5 className={`${styles.title} ${styles.textBlue}`}>
                          Gold Super Saver
                        </h5>
                        <span className={styles.text}>
                          Top 2% of kWh reducers this event
                        </span>
                      </div>
                    ) : null}
                  </div>
                ) : rewards ? (
                  <div>
                    <h5 className={`${styles.title} ${styles.textBlue}`}>
                      Bronze
                    </h5>
                    <span className={styles.text}>Almost!</span>
                  </div>
                ) : (
                  <div>{/* Show nothing */}</div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className={`${styles.itemFullLine} ${styles.itemsCenter}`}>
            {totalRewards && (
              <div className={styles.currencySymbol}>
                <img
                  src="/static/icons/overview-currency-symbol.svg"
                  alt="overview currency symbol"
                />
              </div>
            )}
            <div className={styles.itemLeft}>
              {totalRewards && totalRewards.cents ? (
                <div>
                  <h5 className={`${styles.title} ${styles.textBlue}`}>
                    {dollars(totalRewards.cents / 100, 0)}
                  </h5>
                  <span className={styles.text}>Total bill credits earned</span>
                </div>
              ) : totalRewards ? (
                <div>
                  <h5 className={`${styles.title} ${styles.textBlue}`}>$0</h5>
                  <span className={styles.text}>Total bill credits earned</span>
                </div>
              ) : (
                <div>{/* Show nothing */}</div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default RewardsOverview;
