import { useState } from "react";
import styles from "./Navigation.module.scss";

export default function Navigation() {
  const [isActive, setIsActive] = useState(false);

  function toggleActive() {
    setIsActive((prevIsActive) => !prevIsActive);
  }

  return (
    <nav id="navigation" className={styles.navigation}>
      <div className={styles.navigationHeader}>
        <a href="https://engie.com.au/">
          <img
            className={styles.logo}
            src="/static/Engie-Logo.svg"
            alt="Engie logo"
          />
        </a>
        <div className={styles.toggleButton}>
          <button onClick={toggleActive}>
            <img src="/static/icons/Menu.svg" alt="Menu" />
          </button>
        </div>
        <div className={styles.navigationLinks}>
          <a
            href="https://engie.com.au/help-centre"
            className={styles.navigationLink}
          >
            <img src="/static/icons/help-engie.svg" alt="Help Centre" />
            Help Centre
          </a>
          <a
            href="https://myengie.engie.com.au"
            className={styles.navigationLink}
          >
            <img src="/static/icons/my-account-engie.svg" alt="MyENGIE" />
            MyENGIE
          </a>
        </div>
      </div>
      <div
        className={`${styles.navigationLinkContainers} ${
          isActive ? `${styles.active}` : ""
        }`}
      >
        <div className={styles.navigationLinkContainer}>
          <a
            href="https://engie.com.au/help-centre"
            className={styles.navigationLink}
          >
            <img src="/static/icons/help-engie.svg" alt="Help Centre" />
            Help Centre
          </a>
        </div>
        <div className={styles.navigationLinkContainer}>
          <a
            href="https://myengie.engie.com.au"
            className={styles.navigationLink}
          >
            <img src="/static/icons/my-account-engie.svg" alt="My Account" />
            MyENGIE
          </a>
        </div>
      </div>
    </nav>
  );
}
