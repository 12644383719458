import React from "react";
import { Link } from "react-router-dom";
import ChartNavigationProps from "../interfaces/ChartNavigationProps";
import styles from "./ChartWeekNavigation.module.scss";

const ChartWeekNavigation: React.FC<ChartNavigationProps> = (props) => {
  const { accountId, prevLink, nextLink, intervalRange, isWeekView } = props;

  return (
    <div className={styles.chartNavigationInner}>
      {prevLink && (
        <Link
          className={`${styles.button} ${styles.buttonPrevious}`}
          to={{
            pathname: `/new/usage/${accountId}/${prevLink.from}`,
          }}
        >
          <div className={styles.buttonClicker}></div>
          <img
            className={styles.navigationIcon}
            src="/static/icons/navigation-arrow-left.svg"
            alt="Previous week"
          />
          <span className={styles.buttonHiddenLabel}>Previous week</span>
        </Link>
      )}
      {isWeekView && intervalRange && (
        <h3 className={styles.weekViewNavigation}>
          {intervalRange.starts_at && (
            <span>
              {intervalRange.starts_at.date}
              {intervalRange.starts_at.month !== intervalRange.ends_at.month &&
                ` ${intervalRange.starts_at.month}`}
            </span>
          )}
          {intervalRange.ends_at && (
            <span>
              {" \u2013 "}
              {intervalRange.ends_at.date} {intervalRange.ends_at.month}{" "}
              {intervalRange.ends_at.yyyymmdd.substring(0, 4)}
            </span>
          )}
        </h3>
      )}
      {nextLink && (
        <Link
          className={`${styles.button} ${styles.buttonNext}`}
          to={{
            pathname: `/new/usage/${accountId}/${nextLink.from}`,
          }}
        >
          <div className={styles.ButtonClicker}></div>
          <img
            className={styles.navigationIcon}
            src="/static/icons/navigation-arrow-right.svg"
            alt="Next week"
          />
          <span className={styles.buttonHiddenLabel}>Next week</span>
        </Link>
      )}
    </div>
  );
};
export default ChartWeekNavigation;
