import { useEffect } from "react";
import axios from "axios";
import { config } from "../config";

const serverUrl = config.apiURL;

export function useTrackPageVisit(name: string, accountId: string) {
  useEffect(() => {
    let visitData = {
      source: "trust",
      type: "tracker_ui_visit",
      account_identifier: accountId,
      page_name: name,
      url: document.URL,
      additional_data: {
        event_time: new Date(Date.now()).toString(),
      },
    };
    axios.post(serverUrl + "/api/web_interactions", visitData);
  }, [accountId, name]);
}
