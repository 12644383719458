import React, { useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styles from "./OptOut.module.scss";
import Navigation from "../Navigation";
import Legal from "../Legal";
import axios from "axios";
import { useTrackPageVisit } from "../../hooks/useTrackPageVisit";
import { config } from "../../config";

interface OptOutProps {
  onAlert: (message: string, type: string) => void;
}

const OptOut: React.FC<OptOutProps> = (props) => {
  const { accountId } = useParams();
  const { onAlert } = props;
  useTrackPageVisit("here_for_you_opt_out", accountId as string);

  const navigate = useNavigate();

  function optOut() {
    axios({
      method: "post",
      baseURL: config.innovativeBillingUrl,
      url: `/here_for_you_participants/${accountId}/opt_out`,
      data: {
        client_code: "SIMPLY",
        account_identifier: accountId,
      },
    })
      .then(() => {
        navigate(`/here_for_you/opt_back_in/${accountId}`);
      })
      .catch((e) => {
        if (e.response.status === 304) {
          onAlert("You have already opted out", "info");
        } else {
          onAlert("Error opt out to offer. Please try again.", "warning");
        }
      });
  }
  const checkIfCustomerIsOptedOut = useCallback(
    function checkIfCustomerIsOptedOut() {
      axios({
        method: "get",
        baseURL: config.innovativeBillingUrl,
        url: `/here_for_you_participants/${accountId}`,
        params: { client_code: "SIMPLY" },
      })
        .then((response) => {
          if (!response.data.opted_in) {
            navigate(`/here_for_you/opt_back_in/${accountId}`);
          }
        })
        .catch((e) => {
          if (e.response.status === 404) {
            navigate(`/offer_expired/${accountId}`);
          } else {
            onAlert(
              "Error fetching participant data. Please try again.",
              "warning"
            );
          }
        });
    },
    [navigate, accountId, onAlert]
  );

  useEffect(() => {
    checkIfCustomerIsOptedOut();
  }, [checkIfCustomerIsOptedOut]);

  return (
    <div className={styles.optOutContainer}>
      <Navigation />
      <div className={styles.right}>
        <h2 className={styles.title}>Opt out of Reduce & Be Rewarded</h2>
        <h4>Are you sure?</h4>
        <p className={styles.content}>
          If you opt out, you will no longer be eligible for a $20 bill credit
          if you reduce your electricity usage by 10% over the week.
        </p>
      </div>
      <div className={styles.btnContainer}>
        <button onClick={optOut}>
          <span className={styles.label}>Opt Out</span>
        </button>
      </div>
      <div className={styles.rightSubText}>
        <p>
          Please note, by opting out, you will remain subscribed to your weekly
          Tracker email.
        </p>
      </div>
      <Legal accountId={accountId} hideSettings={true} />
    </div>
  );
};

export default OptOut;
