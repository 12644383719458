import React from "react";
import styles from "./ChangeTimeslotConfirmation.module.scss";
import Navigation from "../Navigation";
import Legal from "../Legal";
import { useParams } from "react-router-dom";
import { useTrackPageVisit } from "../../hooks/useTrackPageVisit";

const ChangeTimeslotConfirmation: React.FC = () => {
  const { timeslot, accountId } = useParams();
  useTrackPageVisit(
    "date_time_saver_change_timeslot_confirmation",
    accountId as string
  );

  return (
    <div className={styles.daytimeSaverChangeTimeslotconfirmation}>
      <Navigation />
      <h1 className={styles.title}>Success!</h1>
      <div className={styles.content}>
        <p>Your free Happy Hours have changed to</p>
        <div className={styles.timeslot}>
          <button
            className={`${styles.commonSelectButton} ${styles.commonSelectButtonSelected}`}
          >
            {timeslot}
          </button>
        </div>
        <p className={styles.lastParagraph}>
          This will take effect the following weekday.
        </p>
      </div>
      <Legal
        accountId={accountId}
        hideFooterImg={true}
        hideSettings={true}
        showAdditionalDisclaimer={true}
      />
    </div>
  );
};
export default ChangeTimeslotConfirmation;
