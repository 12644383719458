import React from "react";
import styles from "./Alert.module.scss";

interface AlertProps {
  registerAlertListener: (
    callback: (content: string, type: string) => void
  ) => void;
  deregisterAlertListener: () => void;
}

interface AlertState {
  content: string;
  type: string;
  active: boolean;
}

export default class Alert extends React.Component<AlertProps, AlertState> {
  static defaultProps = {
    classes: "alert",
  };

  timeoutHandle: number | undefined;

  constructor(props: AlertProps) {
    super(props);
    this.state = {
      content: "--",
      type: "",
      active: false,
    };
  }

  showAlert(content: string, type: string) {
    this.setState({ content, type, active: true });
    this.timeout(() => this.closeAlert(), 3000);
  }

  closeAlert = () => {
    this.setState({ active: false });
  };

  componentDidMount(): void {
    this.props.registerAlertListener((content: string, type: string) =>
      this.showAlert(content, type)
    );
  }

  componentWillUnmount() {
    if (this.timeoutHandle) {
      clearTimeout(this.timeoutHandle);
    }

    this.props.deregisterAlertListener();
  }

  timeout(fn: () => void, delay: number) {
    if (!window.requestAnimationFrame) return;
    let start = new Date().getTime();
    const loop = () => {
      let current = new Date().getTime();
      let delta = current - start;
      delta >= delay
        ? fn()
        : (this.timeoutHandle = window.requestAnimationFrame(loop));
    };
    this.timeoutHandle = window.requestAnimationFrame(loop);
  }

  render() {
    const { content, active, type } = this.state;

    return (
      active && (
        <div
          className={`${styles.alert} ${styles[`alert_${type}`]} ${
            styles.alert_active
          }`}
        >
          <div className={styles.body}>
            {content}
            <button onClick={this.closeAlert}>
              <span className={styles.label}>Close</span>
              <span className={styles.icon}>&times;</span>
            </button>
          </div>
        </div>
      )
    );
  }
}
