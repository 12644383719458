import React from "react";
import ReactDOM from "react-dom";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { dollars } from "../services/Filters";
// import { styled } from 'styled-components';
import styles from "./ChartColumn.module.scss";

interface ChartColumnProps {
  columnIndex: number;
  isWeekView: boolean;
  isDayView: boolean;
  columnIntervalRange: {
    starts_at: { day: string; date: string; time: string };
    ends_at: { day: string; date: string; time: string };
  };
  columnData: any; // Replace 'any' with the actual type
  accountId: string;
  thisWeek: string;
  thisDay: string | null;
}

interface ChartColumnState {
  hasHover: boolean;
  // Define your state properties here
}

export default class ChartColumn extends React.Component<
  ChartColumnProps,
  ChartColumnState
> {
  chartWrap: React.RefObject<HTMLDivElement>;
  chartImage: React.RefObject<SVGSVGElement>;
  constructor(props: ChartColumnProps) {
    super(props);
    this.state = {
      hasHover: false,
      // Initialize your state properties here
    };

    this.chartWrap = React.createRef();
    this.chartImage = React.createRef();
  }

  columnClicked = () => {
    if (this.state.hasHover === true) {
      this.setState({ hasHover: false });
    } else {
      this.setState({ hasHover: true });
    }
  };

  componentDidMount() {
    this.setSVGHeight();
    window.addEventListener("resize", this.handleResize);
    document.addEventListener("click", this.handleClickOutside, true);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    document.removeEventListener("click", this.handleClickOutside, true);
  }

  setSVGHeight() {
    try {
      let boxHeight = this.chartWrap.current?.clientHeight;
      let svg = this.chartImage.current as SVGElement;
      svg.setAttribute("height", `${boxHeight}px`);
      svg.setAttribute("width", `${boxHeight}px`);
    } catch (error) {
      // Log an error?
    }
  }

  handleClickOutside = (event: any) => {
    const domNode = ReactDOM.findDOMNode(this);
    if (!domNode || !domNode.contains(event.target)) {
      this.setState({
        hasHover: false,
      });
    }
  };

  handleResize = () => {
    this.setSVGHeight();
  };

  abbreviateDay(day: string) {
    return day.substring(0, 3);
  }

  get hour() {
    try {
      return parseInt(
        this.props.columnIntervalRange.ends_at.time.split(":")[0]
      );
    } catch (error) {
      return false;
    }
  }
  get classes() {
    var className = "";
    if (this.props.isDayView) className = styles.labelTimeDayView;
    try {
      let interval = parseInt(
        this.props.columnIntervalRange.ends_at.time.replace(":", "")
      );
      if (interval >= 0 && interval % 300 === 0) {
        className = `${styles.labelMod3} ${className}`;
      }
    } catch (error) {
      return false;
    }
    return className;
  }
  get SVGcolumnId() {
    return "average-" + this.props.columnIndex;
  }

  render() {
    return (
      <div
        className={classnames(styles.chartColumn, {
          [styles.chartColumnWeekView]: this.props.isWeekView,
          [styles.chartColumnDayView]: this.props.isDayView,
          [styles.chartColumnIsMax]: this.props.columnData._isMax,
        })}
      >
        {!this.props.columnData ? (
          <div className={styles.noData}>
            <span>{this.props.thisDay}</span>
            <span>No data</span>
          </div>
        ) : (
          <React.Fragment>
            <div className={styles.chart}>
              <div ref={this.chartWrap} className={styles.chartWrap}>
                <svg
                  ref={this.chartImage}
                  className={styles.chartImage}
                  width="100"
                  height="100"
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  viewBox="0 0 100 100"
                  preserveAspectRatio="none"
                >
                  {!this.props.columnData.grid_spend &&
                  this.props.columnData.total_spend &&
                  this.props.columnData.total_spend >= 0 ? (
                    <rect
                      className={`${styles.totalSpend} total_spend`}
                      x="0"
                      y={this.props.columnData._total_spend_column.y}
                      width="100"
                      height={this.props.columnData._total_spend_column.height}
                    ></rect>
                  ) : null}
                  {this.props.columnData.grid_spend &&
                  this.props.columnData.grid_spend >= 0 ? (
                    <rect
                      className={`${styles.gridSpend} grid_spend`}
                      x="0"
                      y={this.props.columnData._grid_spend_column.y}
                      width="100"
                      height={this.props.columnData._grid_spend_column.height}
                    ></rect>
                  ) : null}
                  {!this.props.columnData.grid_spend &&
                  this.props.columnData.total_spend &&
                  this.props.columnData.total_spend < 0 ? (
                    <rect
                      className={`${styles.solarInput} solar_input`}
                      x="0"
                      y={this.props.columnData._total_spend_column.y}
                      width="100"
                      height={this.props.columnData._total_spend_column.height}
                    ></rect>
                  ) : null}
                  {this.props.columnData._service_charge_column ? (
                    <rect
                      className={`${styles.serviceCharge} service_charge`}
                      x="0"
                      y={this.props.columnData._service_charge_column.y}
                      width="100"
                      height={
                        this.props.columnData._service_charge_column.height
                      }
                    ></rect>
                  ) : null}
                  {this.props.columnData._solar_input_column ? (
                    <rect
                      className={`${styles.solarInput} solar_input`}
                      x="0"
                      y={this.props.columnData._solar_input_column.y}
                      width="100"
                      height={this.props.columnData._solar_input_column.height}
                    ></rect>
                  ) : null}
                  {this.props.columnData._budget_column ? (
                    <line
                      className={styles.budget}
                      x1="0"
                      x2="100"
                      strokeDasharray="4, 2"
                      y1={this.props.columnData._budget_column.y + 1}
                      y2={this.props.columnData._budget_column.y + 1}
                    ></line>
                  ) : null}
                  {this.props.columnData._average_spend_column ? (
                    <circle
                      id={this.SVGcolumnId}
                      className={styles.average}
                      cx="50"
                      cy={this.props.columnData._average_spend_column.y}
                      r="1.8"
                    ></circle>
                  ) : null}
                </svg>
              </div>
            </div>
            {this.props.columnData ? (
              <div
                className={classnames(styles.labels, {
                  [styles.hover]: this.state.hasHover,
                })}
                onClick={this.columnClicked}
              >
                {this.props.columnData.total_spend ||
                this.props.columnData.total_spend === 0 ? (
                  <div className={`${styles.label} ${styles.labelTotal}`}>
                    <span>{dollars(this.props.columnData.total_spend)}</span>
                  </div>
                ) : (
                  <div
                    className={`${styles.label} ${styles.labelTotal} ${styles.labelNoData}`}
                  >
                    <span>No data</span>
                  </div>
                )}
                {this.props.columnIntervalRange ? (
                  <div
                    className={classnames(
                      `${styles.label} ${styles.labelTime}`,
                      this.classes
                    )}
                  >
                    {this.props.isWeekView && (
                      <>
                        <span className={styles.timeDay}>
                          {this.abbreviateDay(
                            this.props.columnIntervalRange.starts_at.day
                          )}
                        </span>
                        <span className={styles.timeDate}>
                          {this.props.columnIntervalRange.starts_at.date}
                        </span>
                      </>
                    )}
                    {this.props.isDayView && (
                      <>
                        <span className={styles.timeHour}>{this.hour}</span>
                        <span className={styles.timeMinutes}>:00</span>
                      </>
                    )}
                  </div>
                ) : null}
              </div>
            ) : null}
            {this.props.columnData && this.props.isWeekView ? (
              <div className={styles.overlay}>
                {this.props.columnData.total_spend ||
                this.props.columnData.total_spend === 0 ? (
                  <div className={`${styles.label} ${styles.labelTotal}`}>
                    <span>{dollars(this.props.columnData.total_spend)}</span>
                  </div>
                ) : (
                  <div
                    className={`${styles.label} ${styles.labelTotal} ${styles.labelNoData}`}
                  >
                    <span>No data</span>
                  </div>
                )}
                {this.props.columnIntervalRange ? (
                  <div
                    className={classnames(
                      `${styles.label} ${styles.labelTime}`,
                      this.classes
                    )}
                  >
                    {this.props.isWeekView && (
                      <>
                        <span className={styles.timeDay}>
                          {this.abbreviateDay(
                            this.props.columnIntervalRange.starts_at.day
                          )}
                        </span>
                        <span className={styles.timeDate}>
                          {this.props.columnIntervalRange.starts_at.date}
                        </span>
                      </>
                    )}
                  </div>
                ) : null}
                <div className={styles.labelValue}>
                  {this.props.columnData.grid_spend ||
                  this.props.columnData.grid_spend === 0 ? (
                    <div className={styles.value}>
                      <svg viewBox="0 0 12 12" width="12" height="12">
                        <use xlinkHref="#legend-total_spend_circle"></use>
                      </svg>
                      <span>{dollars(this.props.columnData.grid_spend)}</span>
                    </div>
                  ) : null}
                  {this.props.columnData.service_charge ? (
                    <div className={styles.value}>
                      <svg viewBox="0 0 12 12" width="12" height="12">
                        <use xlinkHref="#legend-service_charge_circle"></use>
                      </svg>
                      <span>
                        {dollars(this.props.columnData.service_charge)}
                      </span>
                    </div>
                  ) : null}
                  {this.props.columnData.solar_input ? (
                    <div className={styles.value}>
                      <svg viewBox="0 0 12 12" width="12" height="12">
                        <use xlinkHref="#legend-solar_input_circle"></use>
                      </svg>
                      <span>{dollars(-this.props.columnData.solar_input)}</span>
                    </div>
                  ) : null}
                  {this.props.columnData.average_spend ? (
                    <div className={styles.value}>
                      <svg viewBox="0 0 12 12" width="12" height="12">
                        <use xlinkHref="#legend-average_spend"></use>
                      </svg>
                      <span>
                        {dollars(this.props.columnData.average_spend)}
                      </span>
                    </div>
                  ) : null}
                </div>
                <div className={styles.dayView}>
                  <Link
                    className={styles.button}
                    to={{
                      pathname: `/new/usage/${this.props.accountId}/${this.props.thisWeek}/${this.props.columnIndex}`,
                    }}
                  >
                    VIEW DAY
                  </Link>
                </div>
              </div>
            ) : null}
          </React.Fragment>
        )}
      </div>
    );
  }
}
