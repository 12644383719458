import React from "react";
import { useEffect, useCallback } from "react";
import styles from "./EventChart.module.scss";
import { Link } from "react-router-dom";
import * as d3 from "d3";

interface Interval {
  local_interval_start_time: string;
  interval_usage_value: number;
}
interface ChartData {
  challenge: { percent_displacement_factor: number };
  usage: {
    baseline: number;
    average: number;
    intervals: Interval[];
  };
}

interface EventChartProps {
  prevEventLink: null | string;
  nextEventLink: null | string;
  chartDate: null | string;
  chartData: null | ChartData;
}
const EventChart: React.FC<EventChartProps> = (props) => {
  const { prevEventLink, nextEventLink, chartDate, chartData } = props;

  const clearCanvas = useCallback(function clearCanvas() {
    d3.select("#chart").selectAll("*").remove();
  }, []);

  const drawChart = useCallback(
    function drawChart() {
      const data = chartData as ChartData;
      // Set margins so that the graph sits nicely in the svg that's created
      const margin = { top: 80, right: 15, bottom: 40, left: 60 };
      const width = 1000 - margin.left - margin.right;
      const height = 1000 - margin.bottom - margin.top;
      const viewBoxSize = Math.min(
        width + margin.left + margin.right,
        height + margin.top + margin.bottom
      );
      const numIntervals = data.usage.intervals.length;
      const target =
        (data.usage.baseline +
          data.challenge.percent_displacement_factor *
            Math.abs(data.usage.baseline)) /
        numIntervals;
      const average = data.usage.average;
      // strokeWidth for target bars
      const strokeWidth = 4;
      var svg = d3
        .select("#chart")
        .append("svg")
        .attr("id", "drGraph")
        .attr("width", width + margin.left + margin.right)
        .attr("height", "100%")
        .attr("viewBox", `0 0 ${viewBoxSize} ${viewBoxSize}`)
        .attr("preserveAspectRatio", "xMinYMin meet")
        .attr("background-color", "#FFF");

      var g = svg
        .append("g")
        .attr("class", "chart")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
      // set the scales for the x and y axis
      var xScale = d3
        .scaleBand()
        .range([0, width])
        .padding(0.1)
        .domain(
          data.usage.intervals.map(
            (interval) => interval.local_interval_start_time
          )
        );
      var yScale = d3
        .scaleLinear()
        .range([height, 0])
        .domain([
          d3.min([
            0,
            target,
            d3.min(
              data.usage.intervals,
              (interval) => interval.interval_usage_value
            ) as number,
          ]) as number,
          d3.max([
            0,
            target,
            d3.max(
              data.usage.intervals,
              (interval) => interval.interval_usage_value
            ) as number,
          ]) as number,
        ])
        .nice();

      // @ts-ignore
      var xAxis = d3.axisBottom().scale(xScale);
      // var yAxis = d3.axisLeft().scale(yScale).ticks(5).tickFormat(d3.format('.2f'))

      // create the x-axis
      g.append("g")
        .attr("class", "x-axis")
        .attr("transform", "translate(0," + height + ")")
        .attr("fill", "#333")
        .style("font-size", "20px")
        .call(xAxis);
      // create the y-axis
      // g.append('g')
      //   .attr('class', 'y-axis')
      //   .style('font-size', '20px')
      //   .call(yAxis)
      // create the bars
      g.selectAll(".bar")
        .data(() => {
          return data.usage.intervals;
        })
        .enter()
        .append("rect")
        // @ts-ignore
        .attr("x", (d) => xScale(d.local_interval_start_time))
        .attr("y", (d) => {
          if (d.interval_usage_value >= 0) {
            return yScale(d.interval_usage_value);
          } else {
            return yScale(0);
          }
        })
        .attr("width", () => xScale.bandwidth())
        .attr("height", (d) => {
          if (d.interval_usage_value >= 0) {
            return yScale(0) - yScale(d.interval_usage_value);
          } else {
            return yScale(d.interval_usage_value) - yScale(0);
          }
        })
        .attr("fill", (d) =>
          d.interval_usage_value >= 0 ? "#33B2E1" : "#FFD600"
        );

      // Add the zero line
      var zeroLine = g.append("g");
      zeroLine
        .append("line")
        .attr("x1", 0)
        .attr("y1", yScale(0))
        .attr("x2", width + 10)
        .attr("y2", yScale(0))
        .attr("stroke-width", 2)
        .attr("stroke", "6")
        .attr("stroke", "#000000");

      // TODO: Add actual average data
      var averageLine = g.append("g");
      averageLine
        .append("line")
        .attr("x1", 0)
        .attr("y1", yScale(average))
        .attr("x2", width + 10)
        .attr("y2", yScale(average))
        .attr("stroke-width", strokeWidth)
        .attr("stroke", "6")
        .attr("stroke", "#BED600");

      // add the target lines
      var targetLine = g.append("g");
      targetLine
        .append("line")
        .attr("x1", 0)
        .attr("y1", yScale(target))
        .attr("x2", width + 10)
        .attr("y2", yScale(target))
        .attr("stroke-width", strokeWidth)
        .attr("stroke-dasharray", "6, 3")
        .attr("stroke", "#FE9000");

      // this removes the lines that mark the x and y axis
      d3.selectAll(".domain").remove();
      d3.selectAll(".tick line").remove();

      // only show every second x axis marker
      // var ticks = d3.selectAll('.x-axis .tick text')
      // ticks.attr('class', function (d, i) {
      //   if (i % 2 !== 0) d3.select(this).remove()
      // })

      g.append("div").attr("class", "");
    },
    [chartData]
  );

  const initializeChart = useCallback(
    function initializeChart() {
      if (chartData != null) {
        drawChart();
      }
    },
    [chartData, drawChart]
  );

  useEffect(() => {
    clearCanvas();
    initializeChart();
  }, [chartData, clearCanvas, initializeChart]);

  return (
    <div className={styles.eventChart}>
      <div className={styles.chartNavigation}>
        <div className={styles.chartNavigationInner}>
          {prevEventLink && (
            <div className={`${styles.button} ${styles.buttonPrevious}`}>
              <Link
                to={{
                  pathname: prevEventLink,
                }}
                className={styles.buttonClicker}
              >
                <img
                  className={styles.navigationIcon}
                  src="/static/icons/navigation-arrow-left.svg"
                  alt="Previous event"
                />
                <span className={styles.buttonHiddenLabel}>Previous event</span>
              </Link>
            </div>
          )}
          <p className={styles.chartDate}>{chartDate}</p>
          {nextEventLink && (
            <div className={`${styles.button} ${styles.buttonNext}`}>
              <Link
                to={{
                  pathname: nextEventLink,
                }}
                className={styles.buttonClicker}
              >
                <img
                  className={styles.navigationIcon}
                  src="/static/icons/navigation-arrow-right.svg"
                  alt="Next event"
                />
                <span className={styles.buttonHiddenLabel}>Next event</span>
              </Link>
            </div>
          )}
        </div>
      </div>
      <div className={styles.chart} id="chart" />
      <div className={styles.ledger}>
        <div className={styles.row}>
          <div className={`${styles.circle} ${styles.orangeCircle}`}></div>
          <div className={styles.message}>
            <p>
              The orange dotted line shows your target for the event, based on
              historical usage, which is set at 30% below your baseline for the
              event.
            </p>
          </div>
        </div>
        <div className={styles.row}>
          <div className={`${styles.circle} ${styles.greenCircle}`}></div>
          <div className={styles.message}>
            <p>
              The solid green line shows your average for the event. If it stays
              below the orange dotted line, you’ll receive a credit.
            </p>
          </div>
        </div>
        <div className={styles.row}>
          <div className={`${styles.circle} ${styles.blueCircle}`}></div>
          <div className={styles.message}>
            <p>
              The blue bars show how much electricity you used during each 30
              minute interval during the event.
            </p>
          </div>
        </div>
        <div className={styles.row}>
          <div className={`${styles.circle} ${styles.yellowCircle}`}></div>
          <div className={styles.message}>
            <p>
              For solar customers, if you export to the grid more than you
              consume during a 30 minute interval, this will show as a yellow
              bar on the graph.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EventChart;
