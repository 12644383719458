import React from "react";
import styles from "./OfrerExpired.module.scss";
import Navigation from "../Navigation";
import personSittingImage from "../../assets/images/daytime-saver/SEN0645_Person_Sitting.png";
import Legal from "../Legal";
import { useParams } from "react-router-dom";
import { useTrackPageVisit } from "../../hooks/useTrackPageVisit";

const OfferExpired: React.FC = () => {
  const { accountId } = useParams();
  useTrackPageVisit("offer_expired", accountId as string);

  return (
    <div className={styles.offerExpiredContainer}>
      <Navigation />
      <div className={styles.content}>
        <div className={styles.left}>
          <img src={personSittingImage} alt="offer expired" />
        </div>
        <div className={styles.right}>
          <h1 className={styles.title}>Sorry</h1>
          <h2>This program is now full.</h2>
        </div>
      </div>
      <Legal accountId={accountId} hideSettings={true} />
    </div>
  );
};
export default OfferExpired;
