import React from "react";
import { useEffect, useState, useCallback } from "react";
import Navigation from "./Navigation";
import UsageOverview from "./UsageOverview";
import UsageChart from "./UsageChart";
import axios from "axios";
import InsightsTransformer from "../lib/InsightsTransformer";
import { InsightsResponse, Customer } from "trust-insights-types";
import IntervalTransformer from "../lib/IntervalTransformer";
import {
  IntervalRange,
  WeeklyUsageIntervals,
  DailyIntervalUsageCollection,
  DailyUsageInsights,
  WeeklyUsageInsights,
} from "trust-insights-types";
import Budget from "./Budget";
import Legal from "./Legal";
import styles from "./Usage.module.scss";
import { useRollbar } from "@rollbar/react";
import { WeekDayKey } from "trust-insights-types";
import NextLink from "../interfaces/NextLink";
import PrevLink from "../interfaces/PrevLink";
import ZoomLink from "../interfaces/ZoomLink";
import { config } from "../config";

interface IUsageProps {
  accountId: string;
  from: string;
  day?: string | null;
  onAlert: (message: string, type: string) => void;
  onLoadingChange: (loading: boolean) => void;
}

interface IUsageState {
  noEmail: null | boolean;
  thisWeek: null | string;
  thisDay: null | WeekDayKey;
  isWeekView: boolean;
  isDayView: boolean;
  isCurrentWeek: boolean;
  rawData: null | string;
  usageData: null | InsightsResponse;
  chartData: null | WeeklyUsageIntervals | DailyIntervalUsageCollection;
  localStorageKey: null | string;
  currentWeekAverage?: null | number;
  budget: null | number;
  previousBudget: null | number;
}

interface IOverridableUsageState {
  noEmail?: boolean | null;
  thisWeek?: string | null;
  thisDay?: null | WeekDayKey;
  isWeekView?: boolean | null;
  isDayView?: boolean | null;
  isCurrentWeek?: boolean | null;
  rawData?: string | null;
  usageData?: InsightsResponse | null;
  chartData?: null | WeeklyUsageIntervals | DailyIntervalUsageCollection;
  localStorageKey?: string | null;
  currentWeekAverage?: string | null;
  budget?: number | null;
  previousBudget?: number | null;
}

function getMonday(date: Date) {
  const day = date.getDay() || 7;
  if (day !== 1) {
    date.setHours(-24 * (day - 1) + date.getHours());
  }
  return date;
}

function formatDate(dateString: string | number | Date) {
  let date = new Date(dateString);
  let year = date.getFullYear();
  let month = date.getMonth() + 1 + "";
  if (month.length === 1) {
    month = "0" + month;
  }
  let dayOfMonth = date.getDate() + "";
  if (dayOfMonth.length === 1) {
    dayOfMonth = "0" + dayOfMonth;
  }
  return year + "-" + month + "-" + dayOfMonth;
}

const skipSurvey = false;

const serverUrl = config.apiURL;

const Usage: React.FC<IUsageProps> = (props) => {
  const { onLoadingChange, onAlert } = props;
  const rollbar = useRollbar();

  let thisWeek = null;
  let thisDay = null;
  let isWeekView = false;
  let isDayView = false;
  let isCurrentWeek = false;
  let week = null;
  let day = null;
  if (props.from !== undefined && props.from !== null) {
    week = new Date(props.from as string);
  }

  if (!week) {
    week = getMonday(new Date());
  }
  if (props.day || props.day === "0") {
    day = String(props.day);
  }
  thisWeek = formatDate(week as Date);
  if (week?.toJSON().substring(0, 10) === thisWeek) {
    isCurrentWeek = true;
  }
  thisDay = day as null | WeekDayKey;
  if (thisDay) {
    isDayView = true;
  } else {
    isWeekView = true;
  }

  const [states, setStates] = useState({
    noEmail: null,
    thisWeek: thisWeek,
    thisDay: thisDay,
    isWeekView: isWeekView,
    isDayView: isDayView,
    isCurrentWeek: isCurrentWeek,
    rawData: null,
    usageData: null,
    chartData: null,
    localStorageKey: null,
    currentWeekAverage: null,
    budget: null,
    previousBudget: null,
  } as IUsageState);

  const logInteraction = useCallback(
    function logInteraction(viewType: string) {
      var visitData = {
        type: "tracker_ui_visit",
        source: "trust",
        account_identifier: props.accountId,
        page_name: `Usage_${viewType}`,
        url: document.URL,
        additional_data: {
          event_time: Date().toString(),
        },
      };

      axios.post(serverUrl + "/api/web_interactions", visitData);
    },
    [props.accountId]
  );

  const showLoading = useCallback(
    function showLoading() {
      onLoadingChange(true);
    },
    [onLoadingChange]
  );

  const hideLoading = useCallback(
    function hideLoading() {
      onLoadingChange(false);
    },
    [onLoadingChange]
  );

  const showMessage = useCallback(
    function showMessage(message: string, type: string) {
      onAlert(message, type);
    },
    [onAlert]
  );

  const fetchUsageDataError = useCallback(
    function fetchUsageDataError(e: any) {
      let message;
      if (
        e.response !== undefined &&
        e.response !== null &&
        e.response.status !== undefined &&
        e.response.status !== null
      ) {
        if (e.response.status === 422 || e.response.status === 401) {
          message = "Invalid account ID number";
        } else if (e.response.status === 403) {
          message = "This account does not belong to this provider";
        } else {
          message = "Error getting usage data. Please try again.";
        }
      } else {
        message = "Error getting usage data. Please try again.";
      }
      hideLoading();
      showMessage(message, "warning");

      rollbar.error("Ajax request error (usage data)", e);
    },
    [hideLoading, showMessage, rollbar]
  );

  const getLocalStorageKey = useCallback(
    function getLocalStorageKey() {
      return `${props.accountId}_${states.thisWeek}`;
    },
    [props.accountId, states.thisWeek]
  );

  const saveDataLocally = useCallback(
    function saveDataLocally(data: InsightsResponse) {
      try {
        // Save data to local storage at key localStorageKey and keep for 1 hour
        window.localStorage.setItem(
          getLocalStorageKey(),
          JSON.stringify({ value: data, expiry: Date.now() + 60 * 60 * 1000 })
        );
      } catch (error) {
        // Do nothing
      }
    },
    [getLocalStorageKey]
  );

  const updateUsageData = useCallback(
    function updateUsageData(
      data: InsightsResponse,
      noEmail: boolean | null = null,
      route: string | null = null
    ) {
      const usageData = InsightsTransformer(data);
      const newState: IOverridableUsageState = {
        usageData: usageData,
        budget: usageData?.customer?.budget,
        previousBudget: usageData?.customer?.budget,
        noEmail: noEmail,
      };
      // Set correct data
      if (states.isWeekView) {
        try {
          let intervals: WeeklyUsageIntervals = newState.usageData?.current_week
            ?.intervals as WeeklyUsageIntervals;
          IntervalTransformer(intervals, newState.budget);
          newState.chartData = intervals;
        } catch (error) {
          rollbar.error(error as any);
        }
      }
      if (states.isDayView) {
        try {
          let selectedDay = states.thisDay as WeekDayKey;
          let intervals = (newState.usageData as InsightsResponse).current_week
            .intervals[selectedDay].intervals;
          IntervalTransformer(intervals);
          newState.chartData = intervals;
        } catch (error) {
          rollbar.error(error as any);
        }
      }

      setStates((prevStates) => ({
        ...prevStates,
        usageData: newState.usageData as null | InsightsResponse,
        budget: newState.budget as number | null,
        previousBudget: newState.previousBudget as null | number,
        noEmail: newState.noEmail as null | boolean,
        currentWeekAverage:
          newState.usageData?.current_week.insights.weekly_average_spend,
        chartData: newState.chartData as
          | null
          | WeeklyUsageIntervals
          | DailyIntervalUsageCollection,
      }));
    },
    [states.isDayView, states.isWeekView, states.thisDay, rollbar]
  );

  const fetchUsageData = useCallback(
    function fetchUsageData(controller: AbortController) {
      let error = false;
      showLoading();
      try {
        // retrieve data from local storage
        let data = window.localStorage.getItem(getLocalStorageKey());
        if (data) {
          const parsedData = JSON.parse(data);
          if (Date.now() >= parsedData.expiry) {
            error = true;
          } else {
            updateUsageData(parsedData.value);
            hideLoading();
          }
        } else {
          error = true;
        }
      } catch (e) {
        error = true;
      }
      // LocalStorage failed so fetch data
      if (error) {
        axios({
          method: "get",
          baseURL: serverUrl,
          url: "/api/tracker/usage",
          signal: controller.signal,
          params: { account_id: props.accountId, from: states.thisWeek },
        })
          .then((response) => {
            hideLoading();
            let noEmail = response.data.customer.no_email;
            let route = null;
            if (
              response.data.customer.email_click_count !== undefined &&
              response.data.customer.email_click_count !== null &&
              response.data.customer.email_click_count >= 2 &&
              (response.data.customer.email_click_count + 1) % 3 === 0 &&
              response.data.customer.completed_survey !== undefined &&
              response.data.customer.completed_survey !== null &&
              response.data.customer.completed_survey === false &&
              states.isCurrentWeek === true &&
              skipSurvey === false
            ) {
              route = "survey_accountid_from_route";
              if (response.data.customer.market_segment === "SME") {
                route = "sme_survey_accountid_from_route";
              }
            }
            updateUsageData(response.data, noEmail, route);
            saveDataLocally(response.data);
          })
          .catch((error) => {
            if (error.code === "ERR_CANCELED") return;
            fetchUsageDataError(error);
          });
      }
    },
    [
      fetchUsageDataError,
      getLocalStorageKey,
      hideLoading,
      props.accountId,
      saveDataLocally,
      showLoading,
      states.isCurrentWeek,
      states.thisWeek,
      updateUsageData,
    ]
  );

  useEffect(() => {
    const controller = new AbortController();
    fetchUsageData(controller);
    return () => {
      controller.abort();
    };
  }, [states.thisWeek, fetchUsageData]);

  useEffect(() => {
    try {
      let selectedDay = states.thisDay;
      let intervals: DailyIntervalUsageCollection;
      if (states.usageData && selectedDay) {
        intervals = (states.usageData as InsightsResponse).current_week
          .intervals[selectedDay].intervals;
        IntervalTransformer(intervals);
        setStates((prevStates) => ({ ...prevStates, chartData: intervals }));
      }
    } catch (error) {
      rollbar.error(error as any);
    }
  }, [states.thisDay, logInteraction, states.usageData, rollbar]);

  useEffect(() => {
    setStates((prevStates) => ({
      ...prevStates,
      thisWeek: props.from ? props.from : prevStates.thisWeek,
      thisDay: props.day as WeekDayKey | null,
    }));
  }, [props.from, props.day]);

  useEffect(() => {
    if (states.isWeekView) {
      let intervals: WeeklyUsageIntervals = states.usageData?.current_week
        ?.intervals as WeeklyUsageIntervals;
      IntervalTransformer(intervals, states.budget);
      setStates((prevStates) => ({ ...prevStates, chartData: intervals }));
    }
  }, [
    states.budget,
    states.isWeekView,
    states.usageData?.current_week?.intervals,
  ]);

  function getWeeklyInsightsData() {
    try {
      return states.usageData?.current_week.insights;
    } catch (error) {
      return false;
    }
  }

  function getInsightsData():
    | WeeklyUsageInsights
    | DailyUsageInsights
    | null
    | undefined {
    try {
      if (states.isWeekView) {
        return states.usageData?.current_week?.insights;
      } else {
        return states.usageData?.current_week?.intervals[
          states.thisDay as WeekDayKey
        ].insights;
      }
    } catch (error: any) {
      rollbar.error("insightsData", error);
      return null;
    }
  }

  function getHasSolar(): boolean {
    try {
      if (states.usageData == null) return false;
      if (states.usageData.metadata == null) return false;
      return states.usageData.metadata._hasSolar as boolean;
    } catch (error: any) {
      rollbar.error("hasSolar", error);
      return false;
    }
  }

  function getIntervalRange(): IntervalRange | null {
    const { usageData, isWeekView, isDayView, thisDay } = states;

    try {
      if (usageData == null) return null;
      if (usageData.current_week == null) return null;
      if (isWeekView) {
        return usageData.current_week.interval_range;
      }
      if (isDayView) {
        return usageData.current_week.intervals[thisDay as WeekDayKey]
          .interval_range;
      }
      return null;
    } catch (error: any) {
      rollbar.error("intervalRange", error);
      return null;
    }
  }

  function getCustomerData(): Customer | null | undefined {
    try {
      return states.usageData?.customer;
    } catch (error) {
      return null;
    }
  }

  function updateBudget(value: number) {
    setStates((prevStates) => ({ ...prevStates, budget: value }));
  }

  function saveBudget() {
    showLoading();
    axios({
      method: "post",
      baseURL: "/api",
      url: "/settings/spend_target",
      params: { account_id: props.accountId },
      data: { spend_target: states.budget },
    })
      .then(() => {
        window.localStorage.clear();
        hideLoading();
        if ((states.budget as number) > 0) {
          showMessage(
            `Budget updated to $${states.budget} per week.`,
            "warning"
          );
        } else {
          showMessage("Budget setting disabled.", "error");
        }
      })
      .catch((e) => saveBudgetError(e));
  }

  function saveBudgetError(e: any) {
    hideLoading();
    showMessage("Error saving budget data. Please try again.", "warning");
    rollbar.error("Ajax request error (set budget data)", e);
  }

  function getPrevLink() {
    // Weird approach but making sure 0 doesn't return false
    try {
      if (states.usageData == null) return false;
      if (states.usageData.previous_week.from == null) return false;
      if (!states.usageData.previous_week.has_data) return false;
      let query: any = {};
      // Account ID
      query.accountId = props.accountId;
      // Week
      if (states.isWeekView) {
        // Keep position
        query.savedPosition = true;
        if (!states.usageData.previous_week.has_data) return false;
        query.from = getPrevWeekDateString();
      }
      // Day
      if (states.isDayView) {
        query.savedPosition = true;
        query.from = states.thisWeek;
        const thisDayInt = parseInt(states.thisDay as string);
        query.day = thisDayInt - 1;
        if (thisDayInt === 0) {
          if (!states.usageData.previous_week.has_data) return false;
          query.from = getPrevWeekDateString();
          query.day = 6;
        }
      }
      // Return object
      return query;
    } catch (error) {
      rollbar.error("getPrevLink", error as any);
      return false;
    }
  }
  function getNextLink() {
    // Weird approach but making sure 0 doesn't return false
    try {
      if (states.usageData == null) return false;
      if (states.usageData.next_week.from == null) return false;
      let query: any = {};
      // Account ID
      query.accountId = props.accountId;
      // Week
      if (states.isWeekView) {
        if (states.usageData.next_week.has_data === false) return false;
        query.savedPosition = true;
        query.from = getNextWeekDateString();
      }
      // Day
      if (states.isDayView) {
        query.savedPosition = true;
        query.from = states.thisWeek;
        query.day = parseInt(states.thisDay as string) + 1;
        if (query.day === 7) {
          if (states.usageData.next_week.has_data === false) return false;
          query.from = getNextWeekDateString();
          query.day = 0;
        }
      }
      // Return object
      return query;
    } catch (error) {
      rollbar.error("getNextLink", error as any);
      return false;
    }
  }

  function getNextWeekDateString() {
    let date = new Date(states.thisWeek as string);
    return formatDate(date.setDate(date.getDate() + 7));
  }

  function getPrevWeekDateString() {
    let date = new Date(states.thisWeek as string);
    return formatDate(date.setDate(date.getDate() - 7));
  }

  function getZoomLink() {
    try {
      let query: any = {};
      // Account ID
      query.accountId = props.accountId;
      // Week
      query.from = states.thisWeek;
      // Return object
      return query;
    } catch (error) {
      rollbar.error("getZoomLink", error as any);
      return false;
    }
  }

  useEffect(() => {
    if (states.isDayView) {
      logInteraction("day_view");
    }
    if (states.isWeekView) {
      logInteraction("week_view");
    }
  }, [
    states.isDayView,
    states.isWeekView,
    props.day,
    props.from,
    logInteraction,
  ]);

  if (!props.accountId) {
    return (
      <div className={styles.usageComponent}>
        <div className={styles.error}>
          <h2>Missing account ID number</h2>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.usageComponent}>
      <Navigation />
      <UsageOverview
        isWeekView={states.isWeekView}
        isDayView={states.isDayView}
        weeklyInsightsData={getWeeklyInsightsData()}
        dailyInsightsData={getInsightsData()}
        intervalRange={getIntervalRange()}
        hasSolar={getHasSolar()}
      />
      <UsageChart
        accountId={props.accountId as string}
        isWeekView={states.isWeekView as boolean}
        isDayView={states.isDayView as boolean}
        thisWeek={states.thisWeek as string}
        thisDay={states.thisDay as string}
        prevLink={getPrevLink() as PrevLink}
        nextLink={getNextLink() as NextLink}
        zoomLink={getZoomLink() as ZoomLink}
        intervalRange={getIntervalRange() as IntervalRange}
        chartData={states.chartData as any}
        insightsData={
          getInsightsData() as WeeklyUsageInsights | DailyUsageInsights
        }
        hasSolar={getHasSolar()}
        customerData={getCustomerData() as Customer}
      />
      <Budget
        accountId={props.accountId}
        currentWeekAverage={states.currentWeekAverage}
        budget={states.budget}
        previousBudget={states.previousBudget}
        updateBudget={updateBudget}
        saveBudget={saveBudget}
      />
      {states.noEmail ? <Legal /> : <Legal accountId={props.accountId} />}
    </div>
  );
};

export default Usage;
