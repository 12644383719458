import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import styles from "./ChangeTimeslot.module.scss";
import Navigation from "../Navigation";
import Legal from "../Legal";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useTrackPageVisit } from "../../hooks/useTrackPageVisit";
import { config } from "../../config";

const timeslots = [
  { id: "10:00-12:00", content: "10am - 12pm" },
  { id: "11:00-13:00", content: "11am - 1pm" },
  { id: "12:00-14:00", content: "12pm - 2pm" },
  { id: "13:00-15:00", content: "1pm - 3pm" },
];

interface Timeslot {
  id: string;
  content: string;
}

interface ChangeTimeslotProps {
  onAlert: (message: string, type: string) => void;
}

const ChangeTimeslot: React.FC<ChangeTimeslotProps> = (props) => {
  const { accountId } = useParams();
  const { onAlert } = props;
  const navigate = useNavigate();

  useTrackPageVisit("change_timeslot", accountId as string);

  const [selectedTimeslot, setSelectedTimeslot] = useState<Timeslot | null>(
    null
  );

  const checkOptedIn = useCallback(
    function checkOptedIn() {
      axios({
        method: "get",
        baseURL: config.innovativeBillingUrl,
        url: `/daytime_saver_participants/${accountId}`,
        params: { client_code: "SIMPLY" },
      })
        .then((response) => {
          let data = response.data;
          if (data && !data.opted_in) {
            navigate(`/offer_expired/${accountId}`);
          }
        })
        .catch((e) => {
          if (/^Account with id \w+ not found$/.test(e.response.data.error)) {
            navigate(`/offer_expired/${accountId}`);
          } else {
            onAlert("Error fetching offer data. Please try again.", "warning");
          }
        });
    },
    [accountId, onAlert, navigate]
  );

  useEffect(() => {
    checkOptedIn();
  }, [accountId, checkOptedIn]);

  function changeTimeSlot() {
    if (!selectedTimeslot) return;

    axios({
      method: "post",
      baseURL: config.innovativeBillingUrl,
      url: "/daytime_saver_participants",
      data: {
        client_code: "SIMPLY",
        account_identifier: accountId,
        time_slot: selectedTimeslot.id,
      },
    })
      .then((response) => {
        navigate(
          `/day_time_saver/change-timeslot-confirmation/${accountId}/${selectedTimeslot.content}`
        );
      })
      .catch((e) => {
        if (e.response.status === 304) {
          onAlert("Timeslot already selected", "info");
        } else {
          onAlert("Error when changing timeslot. Please try again.", "warning");
        }
      });
  }

  return (
    <div className={styles.changeTimeslotContainer}>
      <Navigation />
      <div className={styles.header}>
        <h1 className={styles.title}>Change your free Happy Hours time slot</h1>
      </div>
      <div className={styles.timeslots}>
        {timeslots.map((timeslot) => (
          <span
            key={timeslot.id}
            className={`${styles.commonSelectButton} ${
              selectedTimeslot === timeslot
                ? `${styles.commonSelectButtonSelected}`
                : ""
            }`}
            onClick={() => setSelectedTimeslot(timeslot)}
          >
            {timeslot.content}
          </span>
        ))}
      </div>
      <div className={styles.btnContainer}>
        <button disabled={!selectedTimeslot} onClick={changeTimeSlot}>
          <span className={styles.legal}>UPDATE</span>
        </button>
      </div>
      <Legal
        accountId={accountId}
        hideSettings={true}
        showAdditionalDisclaimer={true}
      />
    </div>
  );
};

export default ChangeTimeslot;
