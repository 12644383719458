import React, { ReactElement } from "react";
import {
  createBrowserRouter,
  createMemoryRouter,
  RouteObject,
  RouterProvider,
  useParams,
  Navigate,
} from "react-router-dom";
import Home from "./Home";
import Usage from "./Usage";
import Settings from "./Settings";
import { Provider, ErrorBoundary } from "@rollbar/react";
import Rollbar from "rollbar";
import Feedback from "./Feedback";
import Icons from "./Icons";
import Alert from "./Alert";
import Loading from "./Loading";
import { Helmet } from "react-helmet";
import ReduceAndRewardLandingPage from "./ReduceAndReward/LandingPage";
import ReduceAndRewardEventPage from "./ReduceAndReward/EventPage";
import OfferExpired from "./Common/OfferExpired";
import LandingPage from "./DaytimeSaver/LandingPage";
import ChangeTimeslot from "./DaytimeSaver/ChangeTimeslot";
import ChangeTimeslotConfirmation from "./DaytimeSaver/ChangeTimeslotConfirmation";
import Confirmation from "./DaytimeSaver/Confirmation";
import SignupAlreadyCompleted from "./DaytimeSaver/SignupAlreadyCompleted";
import ResidentialSurvey from "./ResidentialSurvey";
import SMESurvey from "./SMESurvey";
import HereForYouOptBackIn from "./HereForYou/OptBackIn";
import HereForYouOptOut from "./HereForYou/OptOut";

export default function usageRouterWrapper(
  type: "browser" | "memory",
  initialRoute: string | null = null
): ReactElement {
  const rollbarConfig: Rollbar.Configuration = {
    accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
    environment: process.env.REACT_APP_ROLLBAR_ENVIRONMENT,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      client: {
        javascript: {
          code_version: "1.0.0",
          source_map_enabled: true,
        },
      },
    },
  };

  let alertListener: ((message: string, type: string) => void) | null = null;

  const onAlert = (message: string, type: string) => {
    if (alertListener) {
      alertListener(message, type);
    }
  };

  const registerAlertListener = (
    listener: (message: string, type: string) => void
  ) => {
    alertListener = listener;
  };

  let loadingListener: ((loading: boolean) => void) | null = null;

  const onLoading = (loading: boolean) => {
    if (loadingListener) {
      loadingListener(loading);
    }
  };

  const registerLoadingListener = (listener: (loading: boolean) => void) => {
    loadingListener = listener;
  };

  const clearLoadingListener = () => {
    loadingListener = null;
  };

  const deregisterAlertListener = () => {
    alertListener = null;
  };

  const routeObjects: RouteObject[] = [
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/new/usage/:accountId/:from/:day",
      element: <RenderDailyUsage />,
    },
    {
      path: "/new/usage/:accountId/:from",
      element: <RenderWeeklyUsage />,
    },
    {
      path: "/new/usage/:accountId",
      element: <RenderWeeklyUsage />,
    },
    {
      path: "/new/settings/:accountId",
      element: (
        <Settings
          onAlert={(message: string, type: string) => onAlert(message, type)}
        />
      ),
    },
    {
      path: "/new/feedback/:accountId",
      element: <Feedback />,
    },
    {
      path: "/ReduceAndReward/:accountId",
      element: (
        <ReduceAndRewardLandingPage
          onLoadingChange={(loading: boolean) => onLoading(loading)}
        />
      ),
    },
    {
      path: "/ReduceAndReward/:accountId/Event/:eventId",
      element: (
        <ReduceAndRewardEventPage
          onLoadingChange={(loading: boolean) => onLoading(loading)}
        />
      ),
    },
    {
      path: "/offer_expired/:accountId",
      element: <OfferExpired />,
    },
    {
      path: "/day_time_saver/signup/:accountId",
      element: (
        <LandingPage
          onAlert={(message: string, type: string) => onAlert(message, type)}
        />
      ),
    },
    {
      path: "/day_time_saver/change-timeslot/:accountId",
      element: (
        <ChangeTimeslot
          onAlert={(message: string, type: string) => onAlert(message, type)}
        />
      ),
    },
    {
      path: "/day_time_saver/change-timeslot-confirmation/:accountId/:timeslot",
      element: <ChangeTimeslotConfirmation />,
    },
    {
      path: "/day_time_saver/confirmation/:accountId/:timeslot",
      element: <Confirmation />,
    },
    {
      path: "/day_time_saver/signup-already-completed/:accountId",
      element: <SignupAlreadyCompleted />,
    },
    {
      path: "/here_for_you/opt_out/:accountId",
      element: (
        <HereForYouOptOut
          onAlert={(message: string, type: string) => onAlert(message, type)}
        />
      ),
    },
    {
      path: "/here_for_you/opt_back_in/:accountId",
      element: (
        <HereForYouOptBackIn
          onAlert={(message: string, type: string) => onAlert(message, type)}
        />
      ),
    },
    {
      path: "/new/survey/:accountId",
      element: (
        <ResidentialSurvey
          onAlert={(message: string, type: string) => onAlert(message, type)}
          onLoadingChange={(loading: boolean) => onLoading(loading)}
        />
      ),
    },
    {
      path: "/new/survey/:accountId/:from",
      element: (
        <ResidentialSurvey
          onAlert={(message: string, type: string) => onAlert(message, type)}
          onLoadingChange={(loading: boolean) => onLoading(loading)}
        />
      ),
    },
    {
      path: "/new/sme_survey/:accountId",
      element: (
        <SMESurvey
          onAlert={(message: string, type: string) => onAlert(message, type)}
          onLoadingChange={(loading: boolean) => onLoading(loading)}
        />
      ),
    },
    {
      path: "/new/sme_survey/:accountId/:from",
      element: (
        <SMESurvey
          onAlert={(message: string, type: string) => onAlert(message, type)}
          onLoadingChange={(loading: boolean) => onLoading(loading)}
        />
      ),
    },
    {
      path: "*",
      element: <Navigate to="/" replace={true} />,
    },
  ];

  if (type === "browser") {
    let router = createBrowserRouter(routeObjects);

    return (
      <React.Fragment>
        <Helmet>
          <link
            href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;700&display=swap"
            rel="stylesheet"
          ></link>
        </Helmet>
        <Provider config={rollbarConfig}>
          <ErrorBoundary>
            <div id="app" className="app">
              <Icons />
              <Alert
                registerAlertListener={registerAlertListener}
                deregisterAlertListener={deregisterAlertListener}
              />
              <Loading
                registerListener={registerLoadingListener}
                clearListener={clearLoadingListener}
              />
              <RouterProvider router={router} />
            </div>
          </ErrorBoundary>
        </Provider>
      </React.Fragment>
    );
  } else {
    const routerOpts: { initialEntries?: any } = {};

    if (initialRoute) {
      routerOpts.initialEntries = [initialRoute];
    }

    let router = createMemoryRouter(routeObjects, routerOpts);
    return (
      <React.Fragment>
        <RouterProvider router={router} />
      </React.Fragment>
    );
  }

  function RenderWeeklyUsage() {
    const { accountId, from } = useParams();
    return (
      <Usage
        accountId={accountId as string}
        from={from as string}
        day={null}
        onAlert={(message: string, type: string) => onAlert(message, type)}
        onLoadingChange={(loading: boolean) => onLoading(loading)}
      />
    );
  }

  function RenderDailyUsage() {
    const params = useParams();

    return (
      <Usage
        accountId={params.accountId as string}
        from={params.from as string}
        day={params.day as string}
        onAlert={onAlert}
        onLoadingChange={onLoading}
      />
    );
  }
}
