import React, { useState } from "react";
import Navigation from "./Navigation";
import styles from "./SMESurvey.module.scss";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useRollbar } from "@rollbar/react";
import { useTrackPageVisit } from "../hooks/useTrackPageVisit";
import { config } from "../config";

interface SMESurveyProps {
  onAlert: (message: string, type: string) => void;
  onLoadingChange: (loading: boolean) => void;
}

interface SMESurveyState {
  [key: string]: any;
}

const serverUrl = config.apiURL;

const SMESurvey: React.FC<SMESurveyProps> = (props) => {
  const { accountId, from } = useParams();
  useTrackPageVisit("survey", accountId as string);

  const navigate = useNavigate();
  const { onAlert, onLoadingChange } = props;
  const rollbar = useRollbar();

  const [state, setState] = useState<SMESurveyState>({
    tenancy: null,
    business_type: null,
    employee_number: null,
    shifts: null,
    monthly_outgoings_energy_spend: null,
    heating_cooling_cost: null,
    roof: null,
    solar_panels: null,
    solar_panels_size: undefined,
    decisions: null,
  });

  function showLoading() {
    onLoadingChange(true);
  }

  function hideLoading() {
    onLoadingChange(false);
  }

  function checkForm() {
    if (
      state.tenancy &&
      state.business_type &&
      state.employee_number &&
      state.shifts &&
      state.monthly_outgoings_energy_spend &&
      state.heating_cooling_cost &&
      state.roof &&
      (state.solar_panels || state.solar_panels_size) &&
      state.decisions
    ) {
      return true;
    }

    onAlert("Please answer all the questions", "warning");

    return false;
  }
  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    let pass = checkForm();

    if (pass !== true) {
      return;
    }

    let profile: { [key: string]: any } = {
      tenancy: state.tenancy,
      business_type: state.business_type,
      employee_number: state.employee_number,
      shifts: state.shifts,
      monthly_outgoings_energy_spend: state.monthly_outgoings_energy_spend,
      heating_cooling_cost: state.heating_cooling_cost,
      roof: state.roof,
      solar_panels: state.solar_panels,
      solar_panels_size: state.solar_panels_size,
      decisions: state.decisions,
    };
    // remove blank or null keys
    Object.keys(profile).forEach(
      (key) =>
        (profile[key] === "" ||
          profile[key] === null ||
          profile[key] === undefined) &&
        delete profile[key]
    );
    save(profile);
  }

  function save(profile: SMESurveyState) {
    showLoading();
    axios({
      method: "patch",
      baseURL: serverUrl,
      url: "/api/accounts/update_profile",
      params: { account_id: accountId },
      data: { profile: profile, source: "survey" },
    })
      .then(() => {
        hideLoading();
        onAlert("Thank you for your time.", "warning");
        from
          ? navigate(`/new/usage/${accountId}/${from}`)
          : navigate(`/new/usage/${accountId}`);
      })
      .catch((e) => saveError(e));
  }

  function saveError(e: Error) {
    hideLoading();
    onAlert("Error saving your responses. Please try again.", "warning");
    rollbar.error("Ajax request error (save survey)", e);
  }

  function skip() {
    from
      ? navigate(`/new/usage/${accountId}/${from}`)
      : navigate(`/new/usage/${accountId}`);
  }

  function onChangeRadioSelect(
    event: React.FormEvent<HTMLDivElement>,
    stateKey: string
  ) {
    setState((prevState) => ({
      ...prevState,
      [stateKey]: (event.target as HTMLInputElement).value,
    }));
  }

  function onChangeSolarPanelsSize(event: React.ChangeEvent<HTMLInputElement>) {
    setState((prevState) => ({
      ...prevState,
      solar_panels_size: event.target.value,
    }));
  }

  return (
    <div>
      <Navigation />
      <div>
        <form onSubmit={handleSubmit} className={styles.form}>
          <div className={styles.banner}>
            <h2>
              Engie Tracker Survey
              <button className={styles.skip} onClick={skip}>
                Skip
              </button>
            </h2>
            <p>
              Thank you for taking the time to complete our small survey. Your
              answers will help ensure the information we share with you is
              useful and relevant.
            </p>
          </div>
          <ol>
            <li className={styles.surveyQuestion} data-testid="question-one">
              How many employees do you have?{" "}
              <span className={styles.required}>Required</span>
              <div
                onChange={(e) => {
                  onChangeRadioSelect(e, "employee_number");
                }}
              >
                <input
                  type="radio"
                  checked={state.employee_number === "less_than_five"}
                  readOnly={true}
                  id="q1_1"
                  value="less_than_five"
                />
                <label htmlFor="q1_1">0-5</label>
                <input
                  type="radio"
                  checked={state.employee_number === "five_to_ten"}
                  readOnly={true}
                  id="q1_2"
                  value="five_to_ten"
                />
                <label htmlFor="q1_2">5-10</label>
                <input
                  type="radio"
                  checked={state.employee_number === "ten_to_fifteen"}
                  readOnly={true}
                  id="q1_3"
                  value="ten_to_fifteen"
                />
                <label htmlFor="q1_3">10-15</label>
                <input
                  type="radio"
                  checked={state.employee_number === "fifteen_plus"}
                  readOnly={true}
                  id="q1_4"
                  value="fifteen_plus"
                />
                <label htmlFor="q1_4">15+</label>
              </div>
            </li>
            <li className={styles.surveyQuestion} data-testid="question-two">
              Shifts worked? <span className={styles.required}>Required</span>
              <div
                onChange={(e) => {
                  onChangeRadioSelect(e, "shifts");
                }}
              >
                <input
                  type="radio"
                  checked={state.shifts === "single"}
                  readOnly={true}
                  id="q2_1"
                  value="single"
                />
                <label htmlFor="q2_1">Single shift</label>
                <input
                  type="radio"
                  checked={state.shifts === "double"}
                  readOnly={true}
                  id="q2_2"
                  value="double"
                />
                <label htmlFor="q2_2">Double shift</label>
                <input
                  type="radio"
                  checked={state.shifts === "24hr"}
                  readOnly={true}
                  id="q2_3"
                  value="24hr"
                />
                <label htmlFor="q2_3">24hr operation weekdays</label>
                <input
                  type="radio"
                  checked={state.shifts === "skip"}
                  readOnly={true}
                  id="q2_4"
                  value="skip"
                />
                <label htmlFor="q2_4">Rather Not Say</label>
              </div>
            </li>
            <li className={styles.surveyQuestion} data-testid="question-three">
              Do you lease, rent or own your own business premises?{" "}
              <span className={styles.required}>Required</span>
              <div
                onChange={(e) => {
                  onChangeRadioSelect(e, "tenancy");
                }}
              >
                <input
                  type="radio"
                  checked={state.tenancy === "rent"}
                  readOnly={true}
                  id="q3_1"
                  value="rent"
                />
                <label htmlFor="q3_1">Rent</label>
                <input
                  type="radio"
                  checked={state.tenancy === "lease"}
                  readOnly={true}
                  id="q3_2"
                  value="lease"
                />
                <label htmlFor="q3_2">Lease</label>
                <input
                  type="radio"
                  checked={state.tenancy === "own"}
                  readOnly={true}
                  id="q3_3"
                  value="own"
                />
                <label htmlFor="q3_3">Own</label>
                <input
                  type="radio"
                  checked={state.tenancy === "skip"}
                  readOnly={true}
                  id="q3_4"
                  value="skip"
                />
                <label htmlFor="q3_4">Rather Not Say</label>
              </div>
            </li>
            <li className={styles.surveyQuestion} data-testid="question-four">
              What type of business do you operate?{" "}
              <span className={styles.required}>Required</span>
              <div
                onChange={(e) => {
                  onChangeRadioSelect(e, "business_type");
                }}
              >
                <input
                  type="radio"
                  checked={state.business_type === "office"}
                  readOnly={true}
                  id="q4_1"
                  value="office"
                />
                <label htmlFor="q4_1">Office</label>
                <input
                  type="radio"
                  checked={state.business_type === "retail"}
                  readOnly={true}
                  id="q4_2"
                  value="retail"
                />
                <label htmlFor="q4_2">Retail</label>
                <input
                  type="radio"
                  checked={state.business_type === "manufacturing"}
                  readOnly={true}
                  id="q4_3"
                  value="manufacturing"
                />
                <label htmlFor="q4_3">Manufacturing</label>
                <input
                  type="radio"
                  checked={state.business_type === "other"}
                  readOnly={true}
                  id="q4_4"
                  value="other"
                />
                <label htmlFor="q4_4">Other</label>
              </div>
            </li>
            <li className={styles.surveyQuestion} data-testid="question-five">
              What percentage of your monthly outgoings does energy spend
              represent?<span className={styles.required}>Required</span>
              <div
                onChange={(e) => {
                  onChangeRadioSelect(e, "monthly_outgoings_energy_spend");
                }}
              >
                <input
                  type="radio"
                  checked={
                    state.monthly_outgoings_energy_spend ===
                    "less_than_three_percent"
                  }
                  readOnly={true}
                  id="q5_1"
                  value="less_than_three_percent"
                />
                <label htmlFor="q5_1">&lt;3%</label>
                <input
                  type="radio"
                  checked={
                    state.monthly_outgoings_energy_spend ===
                    "three_to_six_percent"
                  }
                  readOnly={true}
                  id="q5_2"
                  value="three_to_six_percent"
                />
                <label htmlFor="q5_2">3-6%</label>
                <input
                  type="radio"
                  checked={
                    state.monthly_outgoings_energy_spend ===
                    "greater_than_six_percent"
                  }
                  readOnly={true}
                  id="q5_3"
                  value="greater_than_six_percent"
                />
                <label htmlFor="q5_3">&gt;6%</label>
                <input
                  type="radio"
                  checked={state.monthly_outgoings_energy_spend === "not_sure"}
                  readOnly={true}
                  id="q5_7"
                  value="not_sure"
                />
                <label htmlFor="q5_7">Not sure</label>
              </div>
            </li>
            <li className={styles.surveyQuestion} data-testid="question-six">
              Does your business pay the bill for air conditioning (heating and
              cooling)?
              <span className={styles.required}>Required</span>
              <div
                onChange={(e) => {
                  onChangeRadioSelect(e, "heating_cooling_cost");
                }}
              >
                <input
                  type="radio"
                  checked={state.heating_cooling_cost === "yes"}
                  readOnly={true}
                  id="q6_1"
                  value="yes"
                />
                <label htmlFor="q6_1">Yes</label>
                <input
                  type="radio"
                  checked={state.heating_cooling_cost === "no"}
                  readOnly={true}
                  id="q6_2"
                  value="no"
                />
                <label htmlFor="q6_2">No</label>
                <input
                  type="radio"
                  checked={state.heating_cooling_cost === "skip"}
                  readOnly={true}
                  id="q6_4"
                  value="skip"
                />
                <label htmlFor="q6_4">Rather Not Say</label>
              </div>
            </li>
            <li className={styles.surveyQuestion} data-testid="question-seven">
              Do you own the roof space associated with this business making
              solar panels a viable option to reduce energy costs?
              <span className={styles.required}>Required</span>
              <div
                onChange={(e) => {
                  onChangeRadioSelect(e, "roof");
                }}
              >
                <input
                  type="radio"
                  checked={state.roof === "yes"}
                  readOnly={true}
                  id="q7_1"
                  value="yes"
                />
                <label htmlFor="q7_1">Yes</label>
                <input
                  type="radio"
                  checked={state.roof === "no"}
                  readOnly={true}
                  id="q7_2"
                  value="no"
                />
                <label htmlFor="q7_2">No</label>
                <input
                  type="radio"
                  checked={state.roof === "skip"}
                  readOnly={true}
                  id="q7_4"
                  value="skip"
                />
                <label htmlFor="q7_4">Rather Not Say</label>
              </div>
            </li>
            <li
              className={styles.surveyQuestion}
              data-testid="question-eight-a"
            >
              Do you have solar PV (electric) panels on your roof?{" "}
              <span className={styles.required}>Required</span>
              <div
                onChange={(e) => {
                  onChangeRadioSelect(e, "solar_panels");
                }}
              >
                <input
                  type="radio"
                  checked={state.solar_panels === "no"}
                  readOnly={true}
                  id="q8_1"
                  value="no"
                />
                <label htmlFor="q8_1">No</label>
                <input
                  type="radio"
                  checked={state.solar_panels === "less_than_three_kW"}
                  readOnly={true}
                  id="q8_2"
                  value="less_than_three_kW"
                />
                <label htmlFor="q8_2">&lt;3kW</label>
                <input
                  type="radio"
                  checked={state.solar_panels === "four_to_ten_kW"}
                  readOnly={true}
                  id="q8_3"
                  value="four_to_ten_kW"
                />
                <label htmlFor="q8_3">4-10kW</label>
                <input
                  type="radio"
                  checked={state.solar_panels === "ten_to_twenty_kw"}
                  readOnly={true}
                  id="q8_4"
                  value="ten_to_twenty_kw"
                />
                <label htmlFor="q8_4">10-20kW</label>
                <input
                  type="radio"
                  checked={state.solar_panels === "greater_than_twenty_kW"}
                  readOnly={true}
                  id="q8_5"
                  value="greater_than_twenty_kW"
                />
                <label htmlFor="q8_5">&gt;20kW</label>
              </div>
            </li>
            <div data-testid="question-eight-b">
              If you know the exact size of your system please enter it here.
              i.e 4.6kW?
              <div className={styles.itemFull}>
                <label htmlFor="q8_5">
                  <input
                    type="number"
                    min="0"
                    max="1000"
                    step=".01"
                    id="q8_5"
                    autoComplete="off"
                    value={state.solar_panels_size ?? ""}
                    onChange={onChangeSolarPanelsSize}
                  />
                  <span className={styles.kw}>kW</span>
                </label>
              </div>
            </div>
            <li className={styles.surveyQuestion} data-testid="question-nine">
              Who is the decision maker for the energy account?
              <span className={styles.required}>Required</span>
              <div
                onChange={(e) => {
                  onChangeRadioSelect(e, "decisions");
                }}
              >
                <input
                  type="radio"
                  checked={state.decisions === "owner"}
                  readOnly={true}
                  id="q9_1"
                  value="owner"
                />
                <label htmlFor="q9_1">Owner</label>
                <input
                  type="radio"
                  checked={state.decisions === "accountant"}
                  readOnly={true}
                  id="q9_2"
                  value="accountant"
                />
                <label htmlFor="q9_2">Accountant</label>
                <input
                  type="radio"
                  checked={state.decisions === "facility_manager"}
                  readOnly={true}
                  id="q9_4"
                  value="facility_manager"
                />
                <label htmlFor="q9_4">Facility Manager</label>
                <input
                  type="radio"
                  checked={state.decisions === "other"}
                  readOnly={true}
                  id="q9_5"
                  value="other"
                />
                <label htmlFor="q9_5">Other</label>
              </div>
            </li>
          </ol>
          <button type="submit" className={styles.submit}>
            Submit
          </button>
        </form>
      </div>
      <footer>
        <br />
        <br />
      </footer>
    </div>
  );
};

export default SMESurvey;
