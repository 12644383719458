import React from "react";
import styles from "./Confirmation.module.scss";
import Navigation from "../Navigation";
import Legal from "../Legal";
import personStandingImage from "../../assets/images/daytime-saver/SEN0645_Person_Standing.png";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useTrackPageVisit } from "../../hooks/useTrackPageVisit";

const Confirmation: React.FC = (props) => {
  const { accountId, timeslot } = useParams();
  useTrackPageVisit("date_time_saver_confirmation", accountId as string);
  return (
    <div className={styles.daytimeSaverConfirmation}>
      <Navigation />
      <h1 className={styles.title}>Welcome! Time to start saving</h1>
      <div className={styles.content}>
        <p>
          Congratulations on activating your free Happy Hours. That was simple,
          wasn’t it? Check your weekly Tracker email to watch your savings grow!
        </p>
        <p className={styles.secondParagraph}>
          If you are the original recipient of the Happy Hours invitation email,
          starting tomorrow you’ll receive 2 hours of free electricity usage
          between:
        </p>
        <div className={styles.timeslot}>
          <button
            className={`${styles.commonSelectButton} ${styles.commonSelectButtonSelected}`}
          >
            {timeslot}
          </button>
        </div>
        <p className={styles.lastParagraph}>
          And don’t forget, you can{" "}
          <Link
            to={{
              pathname: `/day_time_saver/change-timeslot/${accountId}`,
            }}
          >
            change your time slot{" "}
          </Link>
          at any time via your weekly Tracker email. But in the meantime, enjoy
          great savings during your free Happy Hours!
        </p>
      </div>
      <div className={styles.contentBottom}>
        <img src={personStandingImage} alt="confirmation" />
      </div>
      <Legal
        accountId={accountId}
        hideFooterImg={true}
        hideSettings={true}
        showAdditionalDisclaimer={true}
      />
    </div>
  );
};
export default Confirmation;
