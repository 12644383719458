import React, { useState, useEffect, useCallback } from "react";
import styles from "./LandingPage.module.scss";
import Navigation from "../Navigation";
import Legal from "../Legal";
import TermsAndConditions from "./TermsAndConditions";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useTrackPageVisit } from "../../hooks/useTrackPageVisit";
import { config } from "../../config";

interface LandingPageProps {
  onAlert: (message: string, type: string) => void;
}

const timeslots = [
  { id: "10:00-12:00", content: "10am - 12pm" },
  { id: "11:00-13:00", content: "11am - 1pm" },
  { id: "12:00-14:00", content: "12pm - 2pm" },
  { id: "13:00-15:00", content: "1pm - 3pm" },
];

interface Timeslot {
  id: string;
  content: string;
}

const LandingPage: React.FC<LandingPageProps> = (props) => {
  const { accountId } = useParams();
  const { onAlert } = props;

  useTrackPageVisit("landing_page", accountId as string);

  const navigate = useNavigate();

  const [selectedTimeslot, setSelectedTimeslot] = useState<Timeslot | null>(
    null
  );
  const [isAgree, setIsAgree] = useState(false);

  const checkSignupAlreadyCompleted = useCallback(
    function checkSignupAlreadyCompleted() {
      axios({
        method: "get",
        baseURL: config.innovativeBillingUrl,
        url: `/daytime_saver_participants/${accountId}`,
        params: { client_code: "SIMPLY" },
      })
        .then((response) => {
          if (response.data.opted_in) {
            navigate(`/day_time_saver/signup-already-completed/${accountId}`);
          }
        })
        .catch((e) => {
          if (e.response.status === 404) {
            navigate(`/offer_expired/${accountId}`);
          } else {
            onAlert(
              "Error fetching participant data. Please try again.",
              "warning"
            );
          }
        });
    },
    [accountId, navigate, onAlert]
  );

  const checkExpiredOffer = useCallback(
    function checkExpiredOffer() {
      let now = new Date().toISOString();
      axios({
        method: "get",
        baseURL: config.innovativeBillingUrl,
        url: "/product_configs/DaytimeSaver",
        params: { client_code: "SIMPLY" },
      })
        .then((response) => {
          let offerData = response.data;
          if (offerData && offerData.signup_end_time) {
            if (now > offerData.signup_end_time) {
              navigate(`/offer_expired/${accountId}`);
            }
          }
        })
        .catch((e) => {
          onAlert("Error fetching offer data. Please try again.", "warning");
        });
    },
    [accountId, navigate, onAlert]
  );

  useEffect(() => {
    checkSignupAlreadyCompleted();
    checkExpiredOffer();
  }, [checkExpiredOffer, checkSignupAlreadyCompleted]);

  function signup() {
    if (!selectedTimeslot) return;

    axios({
      method: "post",
      baseURL: config.innovativeBillingUrl,
      url: "/daytime_saver_participants",
      data: {
        client_code: "SIMPLY",
        account_identifier: accountId,
        time_slot: selectedTimeslot.id,
      },
    })
      .then(() => {
        navigate(
          `/day_time_saver/confirmation/${accountId}/${selectedTimeslot.content}`
        );
      })
      .catch((e) => {
        if (e.response.status === 304) {
          onAlert("You have already signed up with this timeslot", "info");
        } else {
          onAlert("Error signup to offer. Please try again.", "warning");
        }
      });
  }

  return (
    <div className={styles.daytimeSaverLanding}>
      <Navigation />
      <div className={styles.header}>
        <h1 className={styles.title}>
          Getting your free Happy Hours couldn’t be simpler
        </h1>
        <div className={styles.stepContainer}>
          <div className={styles.step}>
            <img className={styles.logo} src="/static/step1.svg" alt="step 1" />
            <h4>Step 1</h4>
            <p>Choose your 2-hour time slot.</p>
          </div>
          <div className={styles.step}>
            <img className={styles.logo} src="/static/step2.svg" alt="step 2" />
            <h4>Step 2</h4>
            <p>Read and agree to the terms and conditions.</p>
          </div>
          <div className={styles.step}>
            <img className={styles.logo} src="/static/step3.svg" alt="step 3" />
            <h4>Step 3</h4>
            <p>Confirm to start saving.</p>
          </div>
        </div>
      </div>
      <div className={styles.daytimeSaverLandingContainer}>
        <div className={styles.timeslotSection}>
          <h2 className={styles.heading}>1. Choose your time slot</h2>
          <div className={styles.timeslots}>
            {timeslots.map((timeslot) => (
              <button
                key={timeslot.id}
                className={`${styles.commonSelectButton} ${styles.timeslot} ${
                  selectedTimeslot === timeslot
                    ? `${styles.commonSelectButtonSelected}`
                    : ""
                }`}
                onClick={() => setSelectedTimeslot(timeslot)}
              >
                {timeslot.content}
              </button>
            ))}
          </div>
          <h4 className={styles.info}>
            Don’t worry, you can change your time slot any time via your weekly
            Tracker email. This will take effect the following weekday.
          </h4>
        </div>
        <div className={styles.termConditionSection}>
          <h2 className={styles.heading}>
            2. Read and agree to the terms and conditions
          </h2>
          <div className={styles.decorationLineTop}></div>
          <div className={styles.content}>
            <TermsAndConditions />
          </div>
          <div className={styles.decorationLineBottom}></div>
          <div className={styles.checkbox}>
            <input
              type="checkbox"
              checked={isAgree}
              onChange={() => setIsAgree((prevIsAgree) => !prevIsAgree)}
            />
            <h4>I agree to the Terms and Conditions</h4>
          </div>
          <div className={styles.decorationLine}></div>
        </div>
        <div className={styles.btnContainer}>
          <h2 className={styles.heading}>3. Let's go</h2>
          <button disabled={!selectedTimeslot || !isAgree} onClick={signup}>
            <span className={styles.label}>Sign up</span>
          </button>
        </div>
      </div>
      <Legal
        accountId={accountId}
        hideSettings={true}
        showAdditionalDisclaimer={true}
      />
    </div>
  );
};

export default LandingPage;
