import React from "react";
import styles from "./TermsAndConditions.module.scss";

const TermsAndConditions: React.FC = () => {
  return (
    <div className={styles.termsAndConditions}>
      <h1>Happy Hours – Special Conditions </h1>
      <br />
      <p>
        If you are the original recipient of the Happy Hours invitation email,
        these Happy Hours - Special Conditions form part of your Agreement for
        the sale of Energy and the conditions applying to your participation in
        the Happy Hours program.
      </p>
      <br />
      <p>
        All capitalised words have the same meaning as defined in our current
        Market Contract Terms and Conditions, unless defined herein.
      </p>
      <ol>
        <li>
          <h3>Meaning of terms </h3>
          <p>
            <strong>Agreement</strong> means the terms and conditions in this
            document, the Details, Energy Plan (if any), our privacy policy,
            direct debit terms (if you set up direct debit), disclosure
            statement, and any other terms you’ve agreed to with us.
          </p>
          <p>
            <strong>Eligibility Criteria</strong> means the conditions you must
            satisfy as set out in the Eligibility Criteria section herein.
          </p>
          <p>
            <strong>Happy Credit</strong> means a dollar amount which is
            equivalent to the usage charges charged within your chosen Time Slot
            during your Participation in this program.
          </p>
          <p>
            <strong>Participate/ Participation</strong> means to undertake and
            continue to meet all requirements pursuant to clause 4.2.
          </p>
          <p>
            <strong>Privacy Policy</strong> means our privacy policy set out
            here: https://simplyenergy.com.au/privacy and available upon
            request.
          </p>
          <p>
            <strong>Term</strong> means [insert] or until terminated pursuant to
            clause 5 herein.
          </p>
          <p>
            <strong>Time Slots</strong> mean the time of the day between Monday
            and Friday (inclusive), over which your Happy Hours Credit will
            accrue. The conditions for this are set out pursuant to clause 4.3
            of these Special Conditions.
          </p>
          <p>
            <strong>Weekday</strong> means day of the week between Monday and
            Friday (inclusive).
          </p>
        </li>
        <li>
          <h3>Eligibility Criteria</h3>
          <p>
            You are eligible for and may continue to Participate in the Happy
            Hours program, if You:
          </p>
          <ol className={styles.alphaList}>
            <li>
              are an existing Simply Energy electricity customer with a current
              Energy Plan;
            </li>
            <li>have your primary residence with Simply Energy. </li>
            <li>
              have a smart meter installed at your property with half hourly
              daily reads; and{" "}
            </li>
            <li>
              are not a current solar customer at the time of signing up to the
              Offer.
            </li>
            <li>you are continuing to comply with the Eligibility Criteria;</li>
            <li>your Agreement has not ended; and</li>
            <li>
              You have been explicitly invited by us via email addressed to You.
            </li>
          </ol>
        </li>

        <li>
          <h3>Specific Notice</h3>
          <p>
            You must give us 20 Business Days’ notice if you will cease to meet
            any of the Eligibility Criteria (for example, if you will move
            Premises).
          </p>
        </li>

        <li>
          <h3>Participation in the Happy Hours program</h3>
          <ol className={styles.customList}>
            <li>
              <p>
                <span className={styles.marker}>4.1.</span> Happy Hours Credit
              </p>
              <ol className={styles.customList}>
                <li>
                  If you meet the Eligibility Criteria and Participate in the
                  Happy Hours program as set out below, We will provide you with
                  the appropriate Happy Hours Credit on the following bill after
                  the credit is accrued.
                </li>
              </ol>
            </li>
            <li>
              <p>
                <span className={styles.marker}>4.2.</span> Participation
              </p>
              <ol className={styles.customList}>
                <p>
                  The provision of the Happy Hours Credit will not commence
                  until You:
                </p>
                <ol className={styles.alphaList}>
                  <li>accept these Happy Hours - Special Conditions; and</li>
                  <li>
                    select one of the following time slots via your Happy Hours
                    invitation email: 10:00 – 12:00pm, 11:00 - 13:00, 12:00pm -
                    14:00 and 13:00 - 15:00 (“Time Slots”) pursuant to clause
                    4.3.{" "}
                  </li>
                </ol>
                <p>When the Term ends, so too does Your Participation.</p>
                <p>
                  Participation in the Happy Hours Program or receipt of the
                  Happy Hours Credit cannot be transferred, refunded, or
                  redeemed for cash.{" "}
                </p>
              </ol>
            </li>
            <li>
              <p>
                <span className={styles.marker}>4.3.</span> Time Slots
              </p>
              <ol className={styles.alphaList}>
                <li>
                  Once you select your Time Slot, it will be confirmed by email
                  receipt to your registered email address.
                </li>
                <li>
                  Your Time Slot will be eligible to accrue Happy Hours Credits
                  from the next Weekday after this confirmation is received.
                </li>
                <li>
                  You can change your Time Slot via Simply Tracker. Any such
                  change will not be effective until the next consecutive
                  Weekday.
                </li>
                <li>You may only amend your Time Slot once every 24 hours.</li>
              </ol>
            </li>
            <li>
              <p>
                <span className={styles.marker}>4.4.</span> Data
              </p>
              <ol className={styles.customList}>
                <p>
                  You agree to make available to us or consent to us obtaining,
                  information relating to:
                </p>
                <ol className={styles.alphaList}>
                  <li>Your chosen time slots;</li>
                  <li>
                    and any other information necessary for us to evaluate or
                    perform the services described in these special conditions.
                  </li>
                  <li>to this ownership and transmission; and</li>
                  <li>
                    that we may use and share this data with any other third
                    party associated with the provision of the Happy Hours
                    program (including our related bodies corporate and any
                    personnel of ours and our related bodies corporate, your
                    distributor and government agencies), in accordance with our
                    Privacy Policy.
                  </li>
                </ol>
                <p>
                  You acknowledge that government agencies may make data
                  available to the public in connection with any agreements or
                  funding arrangements between us and that government agency,
                  and we have no liability for that publication.{" "}
                </p>
              </ol>
            </li>
            <li>
              <p>
                <span className={styles.marker}>4.5.</span> Your Obligations
              </p>
              <ol className={styles.customList}>
                <p>
                  During the <i>Happy Hours Benefit Period</i>, you must:{" "}
                </p>
                <ol className={styles.alphaList}>
                  <li>
                    not interfere with or override in any way the activities
                    described in this contract (including, without limitation,
                    by using any third party software applications and/or
                    tools);
                  </li>
                  <li>
                    if we provide you with any reasonable instruction to ensure
                    that your Participation is in compliance with these Special
                    Conditions, promptly comply with our instruction; and
                  </li>
                  <li>otherwise continue to meet the Eligibility Criteria.</li>
                </ol>
                <p>
                  If any material is developed or created in the course of us
                  providing or your Participating in the Happy Hours program, we
                  will own all rights, including any intellectual property, in
                  that material. You agree to assign your rights in such
                  materials to us on their creation.
                </p>
              </ol>
            </li>
          </ol>
        </li>

        <li>
          <h3>Termination</h3>
          <ol className={styles.customList}>
            <li>
              <p>
                <span className={styles.marker}>5.1.</span> Terminating these
                Special Conditions
              </p>
              <ol className={styles.alphaList}>
                <p>
                  We may terminate these Happy Hours – Special Conditions early
                  if:
                </p>
                <li>
                  you no longer meet any or all of the relevant eligibility
                  criteria as set out herein; or
                </li>
                <li>we give you at least 20 Business Days’ notice.</li>
              </ol>
            </li>
            <li>
              <p>
                <span className={styles.marker}>5.2.</span> Consequences of
                Termination
              </p>
              <ol className={styles.customList}>
                <p>
                  There are no exit fees and you may terminate these Happy Hours
                  – Special Conditions at any time by giving us at least 20
                  Business Days’ notice before your next billing cycle date.
                </p>
                <br />
                <p>
                  If these Happy Hours – Special Conditions are terminated
                  early, whether by you or by us, your Agreement will continue
                  in accordance with those term unless otherwise specified.
                </p>
                <br />
                <p>
                  These Happy Hours – Special Conditions will automatically end
                  if your Agreement has ended with us in accordance with that
                  Agreement (e.g. transferring to another retailer,
                  disconnection, etc.).
                </p>
              </ol>
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
};

export default TermsAndConditions;
