import React from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import Navigation from "../Navigation";
import RewardsOverview from "./RewardsOverview";
import RewardsStructure from "./RewardsStructure";
import EnergySavingTipsBanner from "./EnergySavingTipsBanner";
import Legal from "./Legal";
import { useRollbar } from "@rollbar/react";
import { ReduceAndRewardRewards, ReduceAndRewardEvent } from "trust-insights-types";
import EventHistory from "./EventHistory";
import { useTrackPageVisit } from "../../hooks/useTrackPageVisit";
import { config } from "../../config";

interface LandingPageProps {
  onLoadingChange: (loading: boolean) => void;
}

const LandingPage: React.FC<LandingPageProps> = (props) => {
  const { onLoadingChange } = props;
  const rollbar = useRollbar();

  const { accountId } = useParams();
  const [eventHistory, setEventHistory] = useState<Array<ReduceAndRewardEvent> | null>(null);
  const [totalRewards, setTotalRewards] = useState<null | ReduceAndRewardRewards>(null);
  useTrackPageVisit("landing-page", accountId as string);

  const showLoading = useCallback(
    function showLoading() {
      onLoadingChange(true);
    },
    [onLoadingChange]
  );

  const hideLoading = useCallback(
    function hideLoading() {
      onLoadingChange(false);
    },
    [onLoadingChange]
  );

  const fetchDataError = useCallback(
    function fetchDataError(e: Error) {
      const message = "No data available for this event";
      rollbar.error(message, e);
    },
    [rollbar]
  );

  const fetchEvent = useCallback(
    async function fetchEvent(
      eventId: number,
      eventHistory: Array<ReduceAndRewardEvent>,
      count: number
    ) {
      await axios({
        method: "get",
        baseURL: config.reduceAndRewardUrl,
        url: "dr_events/event_result",
        params: { account_id: accountId, event_id: eventId },
      })
        .then((response) => {
          const eventFound = eventHistory.find(
            (event) => event.dr_event.id === eventId
          );
          const hasData = Object.keys(response.data).length !== 0;
          if (eventFound) eventFound.has_data = hasData;
          if (eventFound && hasData) {
            eventFound.dr_event_rewards = response.data.usage.total_rewards;
            eventFound.event_usage_exists =
              response.data.usage.event_usage_exists;
          }
          // Only refresh data when all data is retrieved
          if (count === eventHistory.length) {
            let newEventHistory = eventHistory.filter((event) => {
              return (
                event.has_data &&
                new Date(event.dr_event.start_time) > new Date("2023-10-01")
              );
            });
            setEventHistory(newEventHistory);
            let cents = 0;
            newEventHistory.forEach((event) => {
              if (event.has_data) {
                cents += event.dr_event_rewards.cents;
              }
            });
            setTotalRewards({ cents: cents });
            hideLoading();
          }
        })
        .catch((e) => fetchDataError(e));
    },
    [hideLoading, fetchDataError, accountId]
  );

  const populateEvents = useCallback(
    async function populateEvents(eventHistory: Array<ReduceAndRewardEvent> | null) {
      if (eventHistory != null) {
        var count = 1;
        for (const event of eventHistory) {
          await fetchEvent(event.dr_event.id, eventHistory, count);
          count++;
        }
      } else {
        throw new Error("Event History is null");
      }
    },
    [fetchEvent]
  );

  const fetchData = useCallback(
    function fetchData() {
      showLoading();
      axios({
        method: "get",
        baseURL: config.reduceAndRewardUrl,
        url: "/tracker/dr_events",
        params: { account_id: accountId },
      })
        .then((response) => {
          populateEvents(response.data.dr_events);
        })
        .catch((e) => fetchDataError(e));
    },
    [showLoading, populateEvents, fetchDataError, accountId]
  );

  useEffect(() => {
    fetchData();
    document.title = "Reduce & Reward Portal";
  }, [fetchData]);

  return (
    <div>
      <Navigation />
      <RewardsOverview
        rewards={null}
        totalRewards={totalRewards}
        showRank={false}
      />
      <EventHistory
        accountId={accountId as string}
        eventHistory={eventHistory}
      />
      <RewardsStructure />
      <EnergySavingTipsBanner />
      <Legal accountId={accountId} />
    </div>
  );
};
export default LandingPage;
