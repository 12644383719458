import React from "react";
import { abbreviate, dollars, wholeNumber } from "../services/Filters";
import { IntervalRange } from "trust-insights-types";
import styles from "./ChartInsights.module.scss";
import { WeeklyUsageInsights, DailyUsageInsights } from "trust-insights-types";

interface Props {
  insightsData: WeeklyUsageInsights | DailyUsageInsights;
  isWeekView: boolean;
  isDayView: boolean;
  intervalRange: IntervalRange;
  className?: string;
}

export default class ChartInsights extends React.Component<Props> {
  trendIcon(trend: any) {
    let icon = "#trend-icon-default";
    if (trend.up || trend.trend === "more") {
      icon = "#trend-icon-up";
    }
    if (trend.down || trend.trend === "less") {
      icon = "#trend-icon-down";
    }
    if (trend.stable) {
      icon = "#trend-icon-stable";
    }
    if (trend.dollar) {
      icon = "#trend-icon-dollar";
    }
    if (trend === "none") {
      icon = "#trend-icon-default";
    }
    return icon;
  }

  render() {
    const { insightsData, isWeekView, isDayView, intervalRange } = this.props;

    if (!insightsData) {
      return null;
    }

    return (
      <div className={`${styles.insights} ${this.props.className}`}>
        <svg style={{ display: "none" }}>
          <defs>
            <polygon
              id="trend-icon-up"
              points="0 7 6 0 12 7 7 7 7 12 5 12 5 7"
            ></polygon>
            <polygon
              id="trend-icon-down"
              points="0 5 5 5 5 0 7 0 7 5 12 5 6 12"
            ></polygon>
            <rect
              id="trend-icon-stable"
              x="0"
              y="5"
              width="12"
              height="2"
            ></rect>
            <circle id="trend-icon-default" cx="6" cy="6" r="4"></circle>
            <text
              id="trend-icon-dollar"
              x="6"
              y="10"
              fontSize="12"
              fontWeight="bold"
              textAnchor="middle"
            >
              $
            </text>
          </defs>
        </svg>

        <div className={styles.insightsInner}>
          {isWeekView && insightsData._total_spend && (
            <div className={`${styles.insight} ${styles.insightSpend}`}>
              <span className={`${styles.trend} ${styles.trendDollars}`}>
                <svg width="12" height="12" viewBox="0 0 12 12">
                  <use xlinkHref={this.trendIcon({ dollar: true })} />
                </svg>
                <b>{dollars(insightsData._total_spend, 2)?.replace("$", "")}</b>
                total spend
              </span>
            </div>
          )}

          {isWeekView &&
            (insightsData as WeeklyUsageInsights).max_spend &&
            (insightsData as WeeklyUsageInsights).max_spend.day && (
              <div className={`${styles.insight} ${styles.insightMaxDay}`}>
                <span className={styles.trend}>
                  <svg width="12" height="12" viewBox="0 0 12 12">
                    <use xlinkHref={this.trendIcon("none")}></use>
                  </svg>
                  <b>
                    {abbreviate(
                      (insightsData as WeeklyUsageInsights).max_spend.day
                    )}
                  </b>
                  biggest day
                </span>
              </div>
            )}
          {isWeekView && insightsData.trend && intervalRange && (
            <div className={`${styles.insight} ${styles.insightAverageWeek}`}>
              <span className={styles.trend}>
                <svg width="12" height="12" viewBox="0 0 12 12">
                  <use xlinkHref={this.trendIcon(insightsData.trend)} />
                </svg>
                {insightsData.trend.down && (
                  <span>
                    <b>
                      {wholeNumber(
                        Math.abs(
                          (insightsData as WeeklyUsageInsights).trend
                            .value_to_times
                        ),
                        "down"
                      )}
                      %
                    </b>
                    from average week
                  </span>
                )}
                {insightsData.trend.up && (
                  <span>
                    <b>{wholeNumber(insightsData.trend.value_to_times)}%</b>
                    from average week
                  </span>
                )}
                {insightsData.trend.stable && <span>average week</span>}
              </span>
            </div>
          )}
          {isDayView &&
            insightsData.trend &&
            insightsData.trend.value_to_times &&
            intervalRange && (
              <div className={`${styles.insight} ${styles.insightAverageDay}`}>
                <span className={styles.trend}>
                  <svg width="12" height="12" viewBox="0 0 12 12">
                    <use xlinkHref={this.trendIcon(insightsData.trend)} />
                  </svg>
                  {insightsData.trend.up && (
                    <span>
                      <b>{wholeNumber(insightsData.trend.value_to_times)}%</b>
                      from average {intervalRange.starts_at.day}
                    </span>
                  )}
                  {insightsData.trend.down && (
                    <span>
                      <b>
                        {wholeNumber(
                          Math.abs(insightsData.trend.value_to_times),
                          "down"
                        )}
                        %
                      </b>
                      from average {intervalRange.starts_at.day}
                    </span>
                  )}
                  {insightsData.trend.stable && (
                    <span>average {intervalRange.starts_at.day}</span>
                  )}
                </span>
              </div>
            )}
          {isDayView &&
            (insightsData as DailyUsageInsights).diversion &&
            (insightsData as DailyUsageInsights).diversion.value && (
              <div className={`${styles.insight} ${styles.insightDiversion}`}>
                <span className={styles.trend}>
                  <svg width="12" height="12" viewBox="0 0 12 12">
                    <use
                      xlinkHref={this.trendIcon(
                        (insightsData as DailyUsageInsights).diversion
                      )}
                    />
                  </svg>
                  <b>
                    {wholeNumber(
                      Math.abs(
                        (insightsData as DailyUsageInsights).diversion
                          .value_to_times
                      )
                    )}
                    %
                  </b>
                  from average in the
                  <b>{(insightsData as DailyUsageInsights).diversion.period}</b>
                </span>
              </div>
            )}
        </div>
      </div>
    );
  }
}
