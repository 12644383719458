import React from "react";
import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import Navigation from "./Navigation";
import axios from "axios";
import { useRollbar } from "@rollbar/react";
import styles from "./Settings.module.scss";
import { useTrackPageVisit } from "../hooks/useTrackPageVisit";
import { config } from "../config";

interface SettingsProps {
  onAlert: (message: string, type: string) => void;
}

interface SettingsState {
  subscribed: null | boolean;
  emailFrequency: string;
  subscriptionType: string;
  nextMailingDate: null;
}

const serverUrl = config.apiURL;

const Settings: React.FC<SettingsProps> = (props) => {
  const rollbar = useRollbar();
  const { accountId } = useParams();
  const { onAlert } = props;
  useTrackPageVisit("settings", accountId as string);

  const [states, setStates] = useState<SettingsState>({
    subscribed: null,
    emailFrequency: "weekly",
    subscriptionType: "Subscriptions::WeeklyEmail",
    nextMailingDate: null,
  });

  const fetchSubscriptionDataError = useCallback(
    function fetchSubscriptionDataError(e: Error) {
      if (/403/.test(e.message)) {
        onAlert("This account does not belong to this provider", "warning");
      } else {
        onAlert(
          "Error fetching subscription data. Please try again.",
          "warning"
        );
      }
      rollbar.error("Ajax request error (fetch subscription data)", e);
    },
    [onAlert, rollbar]
  );

  const fetchSubscriptionData = useCallback(
    function fetchSubscriptionData() {
      axios({
        method: "get",
        baseURL: serverUrl,
        url: "/api/tracker/subscription",
        params: { account_id: accountId },
      })
        .then((response) => {
          let subscriptionData = response.data;
          let subscribed = false;
          if (subscriptionData && subscriptionData.subscribed) {
            subscribed = subscriptionData.subscribed;
          }
          let subscriptionType = "Subscriptions::WeeklyEmail";
          if (subscriptionData && subscriptionData.type) {
            subscriptionType = subscriptionData.type;
          }
          let emailFrequency: string;
          if (subscriptionType === "Subscriptions::WeeklyEmail") {
            emailFrequency = "weekly";
          } else {
            emailFrequency = "monthly";
          }
          setStates((prevStates) => ({
            ...prevStates,
            subscribed: subscribed,
            subscriptionType: subscriptionType,
            emailFrequency: emailFrequency,
            nextMailingDate: subscriptionData.next_mailing_date,
          }));
        })
        .catch((e) => fetchSubscriptionDataError(e));
    },
    [accountId, fetchSubscriptionDataError]
  );

  function subscribe() {
    updateSubscription(true);
  }
  function unsubscribe() {
    updateSubscription(false);
  }

  function updateSubscription(subscribed: boolean) {
    axios({
      method: "post",
      baseURL: serverUrl,
      url: "/api/tracker/subscription",
      data: {
        account_id: accountId,
        data: {
          type: states.subscriptionType,
          subscribed: subscribed,
        },
      },
    })
      .then((response) => {
        setStates({ ...states, subscribed: response.data.subscribed });
        onAlert("Weekly email subscription updated.", "warning");
      })
      .catch((e) => updateSubscriptionError(e));
  }

  function updateSubscriptionError(e: Error) {
    onAlert("Error saving subscription data. Please try again.", "warning");
    rollbar.error("Ajax request error (set subscription data)", e);
  }

  function switchFrequency() {
    axios({
      method: "put",
      baseURL: serverUrl,
      url: "/api/tracker/subscription/change_frequency",
      data: {
        account_id: accountId,
      },
    })
      .then((response) => switchFrequencyResponse(response))
      .catch((e) => switchFrequencyError(e));
  }
  function switchFrequencyResponse(response: any) {
    try {
      let subscribed = response.data.subscribed;
      let subscriptionType = response.data.type;
      let emailFrequency: string;
      if (subscriptionType === "Subscriptions::WeeklyEmail") {
        emailFrequency = "weekly";
      } else {
        emailFrequency = "monthly";
      }
      setStates({
        ...states,
        subscribed: subscribed,
        subscriptionType: subscriptionType,
        emailFrequency: emailFrequency,
      });
      onAlert(
        `Email subscription frequency updated to ${emailFrequency}.`,
        "warning"
      );
      fetchSubscriptionData();
    } catch (e) {
      rollbar.error("Error switching tracker frequency", e as Error);
    }
  }

  function switchFrequencyError(e: Error) {
    onAlert("Error saving subscription data. Please try again.", "warning");
    rollbar.error("Ajax request error (set subscription data)", e);
  }

  function frequencyString() {
    if (states.emailFrequency === "weekly") {
      return "monthly";
    } else {
      return "weekly";
    }
  }

  useEffect(() => {
    fetchSubscriptionData();
  }, [fetchSubscriptionData]);

  return (
    <div className={styles.settings}>
      <Navigation />
      <h1>
        This is a useful tool to keep you up-to-date on your energy usage and
        spend. You can subscribe/unsubscribe from snapshot emails.
      </h1>
      {states.subscribed === null ? (
        <div>
          <h2>Fail to fetch subscription data, please try again.</h2>
        </div>
      ) : (
        <div>
          <div>
            <h2>
              <span>
                {states.subscribed ? (
                  <b> Unsubscribe from snapshot emails alerts </b>
                ) : (
                  <b>Resubscribe to snapshot emails</b>
                )}
              </span>
              <div className={styles.content}>
                {states.subscribed ? (
                  <p>
                    {" "}
                    Should you no longer wish to receive snapshot emails, you
                    can unsubscribe below.{" "}
                  </p>
                ) : (
                  <p>
                    If you previously unsubscribed from snapshots emails you can
                    resubscribe below.
                  </p>
                )}
                <div>
                  {states.subscribed ? (
                    <button onClick={unsubscribe}> Unsubscribe </button>
                  ) : (
                    <button onClick={subscribe}> Subscribe </button>
                  )}
                </div>
              </div>
              {states.subscribed && (
                <div>
                  <span>
                    <b>Change Snapshot alert frequency</b>
                  </span>
                  <div className={styles.content}>
                    <p>
                      If {states.emailFrequency} snapshots is not working for
                      you, then you may change to {frequencyString()} snapshots.
                      The next {states.emailFrequency} update will be on{" "}
                      <b>{states.nextMailingDate}</b>
                    </p>
                    <button onClick={switchFrequency}>
                      {" "}
                      Change to {frequencyString()}{" "}
                    </button>
                  </div>
                </div>
              )}
            </h2>
          </div>
        </div>
      )}
    </div>
  );
};

export default Settings;
