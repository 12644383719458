import styles from "./RewardsStructure.module.scss";

export default function RewardsStructure() {
  return (
    <div className={styles.rewardsStructure}>
      <div className={styles.content}>
        <div className={styles.itemFullLine}>
          <h2 className={styles.textCenter}>
            How much can I receive during an event?
          </h2>
          <h3 className={styles.textCenter}>
            There will be multiple events this summer, so more chances to earn!
          </h3>
          <div className={styles.rewardItems}>
            <div
              data-testid="gold-super-saver-description"
              className={`${styles.rewardItem} ${styles.gold}`}
            >
              <h5>Gold Super Saver</h5>
              <p>Top 2% of kWh reducers</p>
            </div>
            <div
              data-testid="gold-super-saver-bill"
              className={styles.rewardItem}
            >
              <h5>$100</h5>
              <p>Bill credit</p>
            </div>
            <div
              data-testid="silver-saver-description"
              className={`${styles.rewardItem} ${styles.silver}`}
            >
              <h5>Silver Saver</h5>
              <p>Reduced by 30% or more below your baseline</p>
            </div>
            <div data-testid="silver-saver-bill" className={styles.rewardItem}>
              <h5>$10</h5>
              <p>Bill credit</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
