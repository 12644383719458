import React from "react";
import ChartNavigationProps from "../interfaces/ChartNavigationProps";
import ChartWeekNavigation from "./ChartWeekNavigation";
import ChartDayNavigation from "./ChartDayNavigation";
import styles from "./ChartNavigation.module.scss";

const ChartNavigation: React.FC<ChartNavigationProps> = (props) => {
  const { isWeekView, isDayView } = props;

  return (
    <div className={styles.chartNavigation}>
      {isWeekView && <ChartWeekNavigation {...props} />}
      {isDayView && <ChartDayNavigation {...props} />}
    </div>
  );
};

export default ChartNavigation;
