import Navigation from "./Navigation";
import Legal from "./Legal";
import styles from "./Home.module.scss";

export default function Home() {
  return (
    <div>
      <Navigation />
      <div className={styles.error}>
        Please click a link from a Tracker email to access your data. To request
        access to Tracker please contact us on{" "}
        <a href="mailto:ideas.au@engie.com">ideas.au@engie.com</a>
      </div>
      <div className={styles.message}>
        <p>
          Tracker is a service that can help you to monitor and manage your
          energy use, to help put you in control.
        </p>
        <p>
          With Tracker, you can monitor how much electricity you’re using each
          day, set budgets and even get an idea of how much your next bill will
          be.
        </p>
      </div>
      <div className={styles.features}>
        <div className={styles.feature}>
          <img
            src="/static/home/tracker-data.jpg"
            title="We use data from smart meter"
            alt="A smart meter LCD display"
            width="640"
            height="320"
          />
          <div className={styles.featureContent}>
            <h2>Your data</h2>
            <p>
              We use your current data from the smart meter at your home, so all
              information is your unique customer data.
            </p>
          </div>
        </div>
        <div className={styles.feature}>
          <img
            src="/static/home/tracker-usage.jpg"
            title="The Tracker chart feature"
            alt="The Tracker chart feature"
            width="640"
            height="320"
          />
          <div className={styles.featureContent}>
            <h2>Your detailed usage</h2>
            <p>
              In your personalised Tracker portal, you can view your usage by
              hour, to see exactly when you’re using electricity.
            </p>
          </div>
        </div>
        <div className={styles.feature}>
          <img
            src="/static/home/tracker-budget.jpg"
            title="The Tracker budget feature"
            alt="The Tracker budget feature"
            width="640"
            height="320"
          />
          <div className={styles.featureContent}>
            <h2>Manage your usage</h2>
            <p>
              Set yourself a budget and then track your spend. You can also view
              the projected balance for the next quarter.
            </p>
          </div>
        </div>
        <div className={styles.feature}>
          <img
            src="/static/home/tracker-portal.jpg"
            title="The Tracker web app"
            alt="A smart phone showing a Tracker email containing the spend chart"
            width="640"
            height="320"
          />
          <div className={styles.featureContent}>
            <h2>Regular updates</h2>
            <p>
              Choose to receive weekly or monthly updates on how you’re
              tracking. You can also check your portal at any time, from any
              device.
            </p>
          </div>
        </div>
      </div>
      <Legal />
    </div>
  );
}
