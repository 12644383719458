import styles from "./OptBackIn.module.scss";
import React, { useEffect, useCallback } from "react";
import Navigation from "../Navigation";
import Legal from "../Legal";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useTrackPageVisit } from "../../hooks/useTrackPageVisit";
import { config } from "../../config";

interface OptBackInProps {
  onAlert: (message: string, type: string) => void;
}

const OptBackIn: React.FC<OptBackInProps> = (props) => {
  const { accountId } = useParams();
  const { onAlert } = props;

  useTrackPageVisit("here_for_you_opt_back_in", accountId as string);

  const navigate = useNavigate();

  function optBackIn() {
    axios({
      method: "post",
      baseURL: config.innovativeBillingUrl,
      url: "/here_for_you_participants",
      data: {
        client_code: "SIMPLY",
        account_identifier: accountId,
      },
    })
      .then((response) => {
        navigate(`/here_for_you/opt_out/${accountId}`);
      })
      .catch((e) => {
        onAlert("Error opt in to offer. Please try again.", "warning");
      });
  }

  const checkIfCustomerIsOptedIn = useCallback(
    function checkIfCustomerIsOptedIn() {
      axios({
        method: "get",
        baseURL: config.innovativeBillingUrl,
        url: `/here_for_you_participants/${accountId}`,
        params: { client_code: "SIMPLY" },
      })
        .then((response) => {
          if (response.data.opted_in) {
            navigate(`/here_for_you/opt_out/${accountId}`);
          }
        })
        .catch((e) => {
          if (e.response.status === 404) {
            navigate(`/offer_expired/${accountId}`);
          } else {
            onAlert(
              "Error fetching participant data. Please try again.",
              "warning"
            );
          }
        });
    },
    [accountId, navigate, onAlert]
  );

  useEffect(() => {
    checkIfCustomerIsOptedIn();
  }, [checkIfCustomerIsOptedIn]);

  return (
    <div className={styles.optBackInContainer}>
      <Navigation />
      <div className={styles.right}>
        <h2 className={styles.title}>
          You are no longer participating in Reduce & Be Rewarded
        </h2>
      </div>
      <div className={styles.btnContainer}>
        <button onClick={optBackIn}>
          <span className={styles.label}>Opt Back In</span>
        </button>
      </div>
      <Legal accountId={accountId} hideSettings={true} />
    </div>
  );
};
export default OptBackIn;
