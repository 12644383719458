import styles from "./EnergySavingTipsBanner.module.scss";

export default function EnergySavingTipsBanner() {
  return (
    <div className={styles.energySavingTipsBanner}>
      <div className={styles.content}>
        <div className={styles.item}>
          <div className={styles.itemLeft}>
            <img
              src="/static/energy-saving-tip.svg"
              alt="energy saving tips"
            />
          </div>
          <div className={styles.message}>
            <h2>Energy Saving Tips</h2>
            <p>
              Keep it up! Here are some handy tips to help you reduce your
              electricity consumptions for the next event.
            </p>
            <p>
              <a
                href="https://www.simplyenergy.com.au/residential/energy-efficiency/reduce-and-reward/tips"
                className={styles.energySavingTipsButton}
              >
                Energy Saving Tips
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
