import Navigation from "./Navigation";
import Legal from "./Legal";
import styles from "./Feedback.module.scss";
import { useParams } from "react-router-dom";
import { useTrackPageVisit } from "../hooks/useTrackPageVisit";

export default function Feedback() {
  const { accountId } = useParams();
  useTrackPageVisit("feedback", accountId as string);

  return (
    <div className={styles.feedback}>
      <Navigation />
      <div className={styles.pageContent}>
        <h2>Give us feedback</h2>
        <div className={styles.messageContact}>
          <p>
            Please send an email to{" "}
            <a href="mailto:ideas.au@engie.com" className={styles.emailLink}>
              ideas.au@engie.com
            </a>{" "}
            and we will get back to you shortly.
          </p>
        </div>
      </div>
      <Legal accountId={accountId} />
    </div>
  );
}
