import React from "react";
import { useState } from "react";
import styles from "./Budget.module.scss";
import { dollars, wholeNumber } from "../services/Filters";

interface BudgetProps {
  accountId: string;
  currentWeekAverage?: number | null;
  budget: number | null;
  previousBudget: number | null;
  updateBudget: Function;
  saveBudget: Function;
}

const Budget: React.FC<BudgetProps> = (props) => {
  const [newBudget, setNewBudget] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  const initialBudgetValue = props.previousBudget
    ? props.previousBudget.toString()
    : "";
  const budgetValue = isEditing ? newBudget : initialBudgetValue;

  function saveBudget(event: React.FormEvent, budgetValue: string) {
    event.preventDefault();
    props.saveBudget(budgetValue);
  }

  function updateBudget(value: string) {
    setIsEditing(true);
    switch (true) {
      case value === "":
      case value === "-":
        setNewBudget(value);
        props.updateBudget(+initialBudgetValue);
        break;
      case value.substring(0, 1) === "-":
        setNewBudget(value.substring(1));
        props.updateBudget(+value.substring(1));
        break;
      default:
        setNewBudget(String(+value));
        props.updateBudget(+value);
    }
  }

  return (
    <div id="budget" className={styles.budget}>
      <div className={styles.content}>
        <div className={styles.itemBreakLine}></div>
        <div className={styles.item}>
          <h2>Keep track of your budget</h2>
        </div>
        <div className={`${styles.item} ${styles.hidden}`}>
          <img
            src={"/static/icons/budget-information.svg"}
            alt="Budget Information"
          />
          <span>
            {" Your average weekly spend over the last 4 weeks has been "}
            <span>
              {props.currentWeekAverage
                ? `${dollars(wholeNumber(props.currentWeekAverage), 0)}`
                : "$-"}
            </span>
          </span>
        </div>
        <div className={styles.itemFull}>
          <form onSubmit={(event) => saveBudget(event, budgetValue)}>
            <div>
              <label htmlFor="update_budget">
                <span>$</span>
                <input
                  type="number"
                  min="0"
                  max="99"
                  step="1"
                  id="update_budget"
                  autoComplete="off"
                  value={budgetValue}
                  onChange={(event) => updateBudget(event.target.value)}
                />
              </label>
            </div>
            <div>
              <button type="submit">UPDATE BUDGET</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Budget;
