import React from "react";
import { Link } from "react-router-dom";
import ChartNavigation from "./ChartNavigation";
import Chart from "./Chart";
import styles from "./UsageChart.module.scss";
// import WeeklyUsageInsights from "../interfaces/insights_response/WeeklyUsageInsights";
import {
  WeeklyUsageInsights,
  DailyUsageInsights,
  DailyIntervalUsageCollection,
  IntervalRange,
  WeeklyUsageIntervals,
} from "trust-insights-types";
import { Customer } from "trust-insights-types";
import NextLink from "../interfaces/NextLink";
import PrevLink from "../interfaces/PrevLink";
import ZoomLink from "../interfaces/ZoomLink";
import ChartLegend from "./ChartLegend";
import ChartInsights from "./ChartInsights";

interface UsageChartProps {
  accountId: string;
  isWeekView: boolean;
  isDayView: boolean;
  thisWeek: string;
  thisDay: string | null;
  prevLink: PrevLink;
  nextLink: NextLink;
  zoomLink: ZoomLink;
  intervalRange: IntervalRange;
  chartData: WeeklyUsageIntervals | DailyIntervalUsageCollection;
  insightsData: WeeklyUsageInsights | DailyUsageInsights;
  hasSolar: boolean;
  customerData: Customer;
}

const UsageChart: React.FC<UsageChartProps> = (props) => {
  const {
    accountId,
    isWeekView,
    isDayView,
    thisWeek,
    thisDay,
    prevLink,
    nextLink,
    zoomLink,
    intervalRange,
    chartData,
    hasSolar,
    customerData,
    insightsData,
  } = props;

  return (
    <div id="usage" className={styles.usage}>
      <div className={styles.chartNavigationHeader}>
        <div
          className={`${styles.item} ${styles.itemMobile} ${styles.chartNavigationTitle}`}
        >
          <h2>Keep track of your usage</h2>
        </div>
        {isWeekView && (
          <div
            className={`${styles.item} ${styles.chartNavigationInformation}`}
          >
            <div className={styles.information}>
              <img
                src="/static/icons/budget-information.svg"
                alt="Information"
              />
              <span>
                This graph is interactive! Click a column to explore your usage.
              </span>
            </div>
          </div>
        )}
        {isDayView && (
          <div
            className={`${styles.item} ${styles.chartNavigationInformation}`}
          >
            <div>
              <Link
                to={{
                  pathname: `/new/usage/${accountId}/${thisWeek}`,
                }}
              >
                <button>VIEW WEEK</button>
              </Link>
            </div>
          </div>
        )}
      </div>
      <div id="fullscreen" className={styles.layout}>
        <ChartNavigation
          accountId={accountId}
          isWeekView={isWeekView}
          isDayView={isDayView}
          thisWeek={thisWeek}
          thisDay={thisDay}
          prevLink={prevLink}
          nextLink={nextLink}
          zoomLink={zoomLink}
          intervalRange={intervalRange}
        />
        <Chart
          accountId={accountId}
          isWeekView={isWeekView}
          isDayView={isDayView}
          thisWeek={thisWeek}
          thisDay={thisDay}
          prevLink={prevLink}
          nextLink={nextLink}
          chartData={chartData}
        />
        <ChartInsights
          className={styles.layoutChartInsights}
          isDayView={isDayView}
          isWeekView={isWeekView}
          insightsData={insightsData}
          intervalRange={intervalRange}
        />
      </div>
      <div className={styles.static}>
        <ChartLegend
          isDayView={isDayView}
          isWeekView={isWeekView}
          hasSolar={hasSolar}
          customerData={customerData}
        />
      </div>
    </div>
  );
};

export default UsageChart;
