// Solar total
let prepSolar = (input) => {
  let totalSolar = 0
  for (let i in input) {
    let data = input[i].data
    if (data && data.solar_input) {
      totalSolar = totalSolar + data.solar_input
    }
  }
  return totalSolar
}

let prepSpend = (input) => {
  let totalSpend = 0
  for (let i in input) {
    let data = input[i].data
    if (data && data.total_spend) {
      totalSpend = totalSpend + data.total_spend
    }
  }
  return totalSpend
}

// The data exporter
module.exports = function (input) {
  // Check if it has solar
  input.metadata = input.metadata || {}
  input.metadata._hasSolar = false
  for (let i in input.current_week.intervals) {
    try {
      let day = input.current_week.intervals[i]
      if (day.metadata && day.metadata.has_solar) {
        input.metadata._hasSolar = true
      }
      let solarTotal = input.current_week.intervals[i].data.solar_input
      input.current_week.intervals[i].insights._total_solar = solarTotal
    } catch (error) {
      // Do nothing
    }
  }
  // Define intervals
  let intervals = input.current_week.intervals

  // Solar totals
  input.current_week.insights._total_solar = prepSolar(intervals)
  for (let i in input.current_week.intervals) {
    try {
      input.current_week.intervals[i].insights._total_solar = input.current_week.intervals[i].data.solar_input
      input.current_week.intervals[i].insights._solar_kwh = input.current_week.intervals[i].data.solar_kwh
    } catch (error) {
      // Do nothing
    }
  }

  // total spend per day
  input.current_week.insights._total_spend = prepSpend(intervals)
  for (let i in input.current_week.intervals) {
    let spendTotal = input.current_week.intervals[i].data.total_spend
    input.current_week.intervals[i].insights._total_spend = spendTotal
  }

  // Return the data
  return input
}
