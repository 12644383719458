import React from "react";
import { Link } from "react-router-dom";
import LegalProps from "../interfaces/LegalProps";
import styles from "./Legal.module.scss";

const Legal: React.FC<LegalProps> = (props) => {
  function settingsURL() {
    return `/new/settings/${props.accountId}`;
  }

  function feedbackURL() {
    return `/new/feedback/${props.accountId}`;
  }

  function happyHourProgramURL() {
    return "https://engie.com.au/sites/default/files/2024-03/ENG_B12480_Happy_Hours_Conditions_Doc_297x210mm_v1%20%281%29%20%281%29.pdf";
  }

  function faqURL() {
    return "https://engie.com.au/tracker";
  }

  return (
    <div
      id="footer"
      className={
        props.showAdditionalDisclaimer
          ? `${styles.footer} ${styles.footerLonger}`
          : `${styles.footer}`
      }
    >
      <div className={styles.content}>
        <div>
          <p>
            Tracker is designed to help you monitor and manage your electricity
            use. It forecasts your bills by combining the daily usage data from
            your smart meter with your past usage, taking into account seasonal
            variations. It is a projection only and not a substitute for your
            actual bill. The calculations also include usage charges, supply
            charges, concessions, discounts, and solar feed-in credits (if
            applicable). It does not include manual adjustments such as credits,
            forward balances, or other charges such as special meter read fees.
            Tracker usage times are in Australian Eastern Standard Time (AEST).
          </p>
          {props.showAdditionalDisclaimer && (
            <div>
              <br />
              <p>
                This is a targeted offer and is only available to recipient of
                this email and is not transferable. Free power hours apply to
                electricity usage only and excludes all other fees and charges
                (including supply charges). The Happy Hours benefit can be ended
                at any time on 20 Business Days’ notice. For eligibility and
                full terms and conditions please visit{" "}
                <a
                  href={happyHourProgramURL()}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "#37B5E3" }}
                >
                  Happy Hours Program.
                </a>
              </p>
            </div>
          )}
          <br />
          <p>Need to get in touch? Call us on 13 88 08.</p>
          <br />
          <p>
            ENGIE (ABN 67 269 241 237) is a partnership comprising IPower Pty
            Ltd (ACN 111 267 228) and IPower 2 Pty Ltd (ACN 070 374 293).
          </p>
        </div>
        <div className={styles.footerLinks}>
          {props.accountId && !props.hideSettings && (
            <Link to={{ pathname: settingsURL() }}>Settings</Link>
          )}
          {props.accountId && !props.hideSettings && <span> | </span>}
          <a href={faqURL()}>FAQ</a>
          {props.accountId && (
            <>
              <span> | </span>
              <Link to={{ pathname: feedbackURL() }}>Feedback</Link>
            </>
          )}
        </div>
      </div>
      <img src="/static/Circles.svg" className={styles.circles} alt="circles" />
    </div>
  );
};
export default Legal;
