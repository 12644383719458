import ReactDOM from 'react-dom/client';
import usageRouterWrapper from './components/usageRouterWrapper';
import './assets/typography.scss';
import './assets/global.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


root.render(usageRouterWrapper('browser'));
