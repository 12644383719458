import React from "react";
import styles from "./EventHistory.module.scss";
import { Link } from "react-router-dom";
import { formatDate, dollars } from "../../services/Filters";
import { ReduceAndRewardEvent } from "trust-insights-types";

interface EventHistoryProps {
  accountId: string;
  eventHistory: ReduceAndRewardEvent[] | null;
}
const EventHistory: React.FC<EventHistoryProps> = (props) => {
  const { accountId, eventHistory } = props;
  return (
    <div className={styles.eventHistory}>
      <div className={styles.content}>
        <div className={styles.itemFullLine}>
          <h2>Event History</h2>
          <p>
            Click on the date for any event to see your interval data targets
            and performance.
          </p>
        </div>
        <div className={styles.history}>
          {eventHistory && (
            <table>
              <thead>
                <tr>
                  <th scope="col">DATE</th>
                  <th scope="col">RESULT</th>
                  <th scope="col">BILL CREDIT</th>
                  <th scope="col">RANKING</th>
                </tr>
              </thead>
              <tbody>
                {eventHistory.map((event) => (
                  <tr key={event.id}>
                    {event.event_usage_exists ? (
                      <td>
                        <Link
                          className={styles.historyLink}
                          to={{
                            pathname:
                              `/ReduceAndReward/${accountId}` +
                              "/event/" +
                              `${event.dr_event.id}`,
                          }}
                        >
                          {formatDate(
                            event.dr_event.start_time,
                            "DD MMMM YYYY"
                          )}
                        </Link>
                      </td>
                    ) : (
                      <td>
                        {formatDate(event.dr_event.start_time, "DD MMMM YYYY")}
                      </td>
                    )}

                    {!event.event_usage_exists ? (
                      <td>
                        <span className={styles.missingUsageResult}>
                          Missing
                        </span>
                      </td>
                    ) : event.passed ? (
                      <td>
                        <span className={styles.successResult}>Success!</span>
                      </td>
                    ) : (
                      <td>
                        <span className={styles.failResult}>Almost!</span>
                      </td>
                    )}

                    {event.dr_event_rewards && (
                      <td>{dollars(event.dr_event_rewards.cents / 100, 0)}</td>
                    )}

                    {event.event_usage_exists && (
                      <td>
                        {event.dr_event_rewards.cents === 0 ? (
                          <span className={styles.ranking}>Bronze</span>
                        ) : event.dr_event_rewards.cents === 1000 ? (
                          <span className={styles.ranking}>Silver Saver</span>
                        ) : event.dr_event_rewards.cents >= 10000 ? (
                          <span className={styles.ranking}>
                            Gold Super Saver
                          </span>
                        ) : null}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventHistory;
