import React from "react";
import styles from "./Legal.module.scss";
import { Link } from "react-router-dom";

interface LegalProps {
  accountId?: string;
}

const Legal: React.FC<LegalProps> = (props) => {
  const { accountId } = props;

  function feedbackURL() {
    return `/new/feedback/${accountId}`;
  }
  function faqURL() {
    return `https://www.simplyenergy.com.au/residential/energy-efficiency/reduce-and-reward#faqs`;
  }

  return (
    <div id="footer" className={styles.footer}>
      <div className={styles.content}>
        <div>
          <p>
            Simply Energy is backed by ENGIE, a global leader in the transition
            to a zero-carbon economy. ENGIE is committed to developing and
            providing sustainable energy solutions in Australia. Tracker is a
            weekly email designed to keep you up to date on your energy spend
            based on your meter’s electricity usage. It is intended as a guide
            only. It is not your bill. Tracker’s calculations include any
            discounts applicable to your account when these are part of your
            electricity contract. They do not include concessions or loyalty
            credits.
          </p>
          <br />
          <p>
            Simply Energy (ABN 67 269 241 237) is a partnership comprising
            IPower Pty Ltd (ACN 111 267 228) and IPower 2 Pty Ltd (ACN 070 374
            293).
          </p>
        </div>
        <div className={styles.footerLinks}>
          <a href={faqURL()}>FAQs</a>
          {accountId && (
            <>
              <span> | </span>
              <Link
                to={{
                  pathname: feedbackURL(),
                }}
              >
                Feedback
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default Legal;
