import React, { useState, useEffect, useCallback } from "react";
import ChartColumn from "./ChartColumn";
import NextLink from "../interfaces/NextLink";
import PrevLink from "../interfaces/PrevLink";
import { WeeklyUsageIntervals } from "trust-insights-types";
import { DailyIntervalUsageCollection } from "trust-insights-types";
import styles from "./Chart.module.scss";
import { useNavigate } from "react-router-dom";

interface ChartProps {
  thisWeek: string;
  thisDay: string | null;
  isWeekView: boolean;
  isDayView: boolean;
  prevLink: PrevLink;
  nextLink: NextLink;
  accountId: string;
  chartData: WeeklyUsageIntervals | DailyIntervalUsageCollection;
}

const cookieName = "tracker__viewedHint";

function checkFirstVisit() {
  const cookie = document.cookie.match(
    "(^|;) ?" + cookieName + "=([^;]*)(;|$)"
  );
  return !cookie;
}

const Chart: React.FC<ChartProps> = (props) => {
  const {
    chartData,
    isWeekView,
    isDayView,
    accountId,
    thisWeek,
    thisDay,
    prevLink,
    nextLink,
  } = props;

  const [firstVisit, setFirstVisit] = useState(checkFirstVisit());
  const [message, setMessage] = useState<string | null>(null);

  const navigate = useNavigate();

  function onSwipe(event: any) {
    if (event.isFinal === false) {
      return;
    }
    if (event.direction === 4 && prevLink) {
      if (isWeekView) {
        navigate(`/new/usage/${accountId}/${prevLink.from}`);
      } else {
        navigate(`/new/usage/${accountId}/${prevLink.from}/${prevLink.day}`);
      }
    }

    if (event.direction === 2 && nextLink) {
      if (isWeekView) {
        navigate(`/new/usage/${accountId}/${nextLink.from}`);
      } else {
        navigate(`/new/usage/${accountId}/${nextLink.from}/${nextLink.day}`);
      }
    }
  }

  function columnData(index: string) {
    return (chartData as any)[index].data;
  }

  function columnIntervalRange(index: string) {
    return (chartData as any)[index].interval_range;
  }

  function hideHint() {
    document.cookie = `${cookieName}=true; path=/`;
    setFirstVisit(false);
  }

  const getMessage = useCallback(
    function getMessage() {
      try {
        let message: string | null = null;

        if (!chartData[0]) {
          message = `Sorry, it seems this ${
            isWeekView ? "week" : "day"
          } has no data`;
        } else {
          message = "Loading";
        }
        if (chartData && firstVisit) {
          message = null;
        }
        if (chartData && chartData[0]) {
          message = null;
        }

        setMessage(message);
        return message;
      } catch (error) {
        return null;
      }
    },
    [chartData, firstVisit, isWeekView]
  );

  useEffect(() => {
    getMessage();
  }, [getMessage]);

  return (
    <div className={styles.chart} onClick={hideHint}>
      <div className={styles.chartContainer} onTouchMove={onSwipe}>
        {chartData &&
          chartData[0] &&
          Object.entries(chartData).map((value) => (
            <ChartColumn
              key={value[0]}
              columnIndex={Number(value[0])}
              isWeekView={isWeekView}
              isDayView={isDayView}
              columnIntervalRange={columnIntervalRange(value[0])}
              columnData={columnData(value[0])}
              accountId={accountId}
              thisWeek={thisWeek}
              thisDay={thisDay}
            />
          ))}
      </div>
      {chartData && firstVisit && (
        <div className={styles.chartHint} onClick={hideHint}>
          Click a column to explore data
        </div>
      )}
      {message && <div className={styles.chartMessage}>{message}</div>}
    </div>
  );
};

export default Chart;
